import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { initTextEditor } from '../../../helpers/posts.helper';
import { I18nService } from '../../../services/i18n.service';
import { slugify } from '../../../helpers/functions.helper';
import { CategoriesNotificationsService } from '../../../services/collections/categories-notifications.service';
import { Category } from '../../../models/collections/category.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { NotificationsService } from '../../../services/collections/notifications.service';
import { NavigationService } from '../../../services/navigation.service';
import { Post, PostStatus } from '../../../models/collections/notification.model';
import { getEmptyImage } from '../../../helpers/assets.helper';
import { ActivatedRoute } from '@angular/router';
import { Tariff } from '../../../models/tarifs.model';

import {} from 'googlemaps'

let autocomplete: google.maps.places.Autocomplete;
let address1Field: HTMLInputElement;
let address2Field: HTMLInputElement;
let postalField: HTMLInputElement;
let latField: HTMLInputElement;
let lngField: HTMLInputElement;
let localityField: HTMLInputElement;
let stateField: HTMLInputElement;
let countryField: HTMLInputElement;

let addressCity;
let addressCodePostal
let addressState
let addressCountry
let addressStreet
let addressStreetNumber;
let lat 
let lng;


@Component({
  selector: 'fa-posts-edit',
  templateUrl: './posts-edit.component.html',
  styleUrls: ['./posts-edit.component.css']
})
export class NotificationsEditComponent implements OnInit, AfterViewInit, OnDestroy {

  private id: string;
  title: string;
  editor: any;
  status: PostStatus;
  language: string;
  slug: string;
  email:string;
  phone:string;
  redirection:string;
  date: string;
  dateInfo:any;
  private image: File;
  imageSrc: string|ArrayBuffer;
  private logo: File;
  logoSrc: string|ArrayBuffer;
  horaires:{};
  address: {};
  mondayStart:{};
  mondayEnd:{};
  tuesdayStart:{};
  tuesdayEnd:{};
  wednesdayStart:{};
  wednesdayEnd:{};
  thursdayStart:{};
  thursdayEnd:{};
  fridayStart:{};
  fridayEnd:{};
  saturdayStart:{};
  saturdayEnd:{};
  sundayStart:{};
  sundayEnd:{};

  lat:string;
  lng:string;
  street:string;
  streetNumber:string;
  locality:string;
  postcode:string;
  state:string;
  country:string;
  tarifBeforeAdd:any = []
  tarifs: Tariff[] = [
   {
    id: '',
    name: this.getRandomIntInclusive(8),
    nameCarnet: '',
    price: 0,
    tva:20,
    packList:[],
    category:'ticket',
    description:'',
    multi: false,
    slug: this.getRandomIntInclusive(8),
    timeSession:0,
    usePASS:false,
    use:1,
    type:'normal',
    groupMin:0
   }
  ];

  public options: any[] = [{
    name: '',
    price: '',
  }];

  checkedCategories: string;
  categoriesObservable: Observable<Category[]>;
  newCategory: string;
  isSubmitButtonsDisabled: boolean = false;
  allStatus: object|any = {};
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();

  constructor(
    private i18n: I18nService,
    private categories: CategoriesNotificationsService,
    private alert: AlertService,
    private posts: NotificationsService,
    public navigation: NavigationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.allStatus = this.posts.getAllStatus();
    this.isSubmitButtonsDisabled = true;
    this.subscription.add(
      this.route.params.subscribe((params: { id: string }) => {
        // console.log(params);
        this.posts.get(params.id).pipe(take(1)).toPromise().then((post: Post) => {
          // console.log(post);
          if (post) {
            this.id = post.id;
            this.title = post.title;
            this.editor.root.innerHTML = post.content;
            this.status = post.status;
            this.slug = post.slug;
            this.date = new Date(post.date).toISOString().slice(0, 10);
            this.dateInfo = post.date
            this.language = post.lang;
            this.logo = null;
            this.image = null;
            this.imageSrc = getEmptyImage();
            this.logoSrc = getEmptyImage();
            if (post.image) {
              this.posts.getImageUrl(post.image as  string).pipe(take(1)).toPromise().then((imageUrl: string) => {
                this.imageSrc = imageUrl;
              });
            }
            this.checkedCategories = post.categories;
            this.routeParamsChange.next();
            this.setCategoriesObservable();
            this.isSubmitButtonsDisabled = false;
          } else {
            this.navigation.redirectTo('posts', 'list');
          }
        });
      })
    );
    
  }

  addAddress() {
 
    this.tarifs.push({
      id: this.getRandomIntInclusive(8),
      name: '',
      nameCarnet:'',
      price: 0,
      tva:20,
      packList:[],
      category:'ticket',
      description:'',
      multi: false,
      slug: this.getRandomIntInclusive(8),
      timeSession:0,
      usePASS:false,
      use:1,
      type:'normal',
      groupMin:0
    });


  }

  removeAddress(i: number) {
    this.tarifs.splice(i, 1);
  }


  addOption() {
    this.options.push({
      id: this.options.length + 1,
      name: '',
      price: '',
    });
  }

  removeOption(i: number) {
    this.options.splice(i, 1);
  }

  logValue() {
    console.log(this.tarifs);
  }

  ngAfterViewInit() {
    this.editor = initTextEditor('#editor-container', this.i18n.get('PostContent'));

    this.initAutocomplete() 
  }



  

// This sample uses the Places Autocomplete widget to:
// 1. Help the user select a place
// 2. Retrieve the address components associated with that place
// 3. Populate the form fields with those address components.
// This sample requires the Places library, Maps JavaScript API.
// Include the libraries=places parameter when you first load the API.
// For example: <script
// src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">



initAutocomplete() {
  

  address1Field = document.querySelector("#ship-address") as HTMLInputElement;
  address2Field = document.querySelector("#address2") as HTMLInputElement;
  postalField = document.querySelector("#postcode") as HTMLInputElement;
  latField = document.querySelector("#lat") as HTMLInputElement;
  lngField = document.querySelector("#lng") as HTMLInputElement;
  localityField = document.querySelector("#locality") as HTMLInputElement;
  stateField = document.querySelector("#state") as HTMLInputElement;
  countryField = document.querySelector("#country") as HTMLInputElement;

  // Create the autocomplete object, restricting the search predictions to
  // addresses in the France and Belgique.
  autocomplete = new google.maps.places.Autocomplete(address1Field, {
    componentRestrictions: { country: ["fr", "be"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  });
  //address1Field.focus();

  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener("place_changed", this.fillInAddress);
  
}


fillInAddress() {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();

  if (!place.geometry || !place.geometry.location) {
    // User entered the name of a Place that was not suggested and
    // pressed the Enter key, or the Place Details request failed.
    window.alert("Aucune coordonnées GPS pour : '" + place.name + "'");
    return;
  }

  lat = place.geometry.location.lat();
  lng = place.geometry.location.lng();

  let address1 = "";
  let postcode = "";
  let locality = "";


  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        addressStreetNumber = component.long_name;
        address1 = `${component.long_name} ${address1}`;
        break;
      }

      case "route": {
        addressStreet = component.long_name;
        address1 += component.short_name;
        break;
      }

      case "postal_code": {
        addressCodePostal = component.long_name;
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case "locality":
        addressCity = component.long_name;
        (document.querySelector("#locality") as HTMLInputElement).value =
          component.long_name;
        break;

      case "administrative_area_level_1": {
        addressState = component.short_name;
        (document.querySelector("#state") as HTMLInputElement).value =
          component.short_name;
        break;
      }

      case "country":
        addressCountry = component.long_name;
        (document.querySelector("#country") as HTMLInputElement).value =
          component.long_name;
        break;
    }
  }
  
  address1Field.value = address1;
  postalField.value = postcode;
  latField.value = lat;
  lngField.value = lng;

 

  // After filling the form with address components from the Autocomplete
  // prediction, set cursor focus on the second address line to encourage
  // entry of subpremise information such as apartment, unit, or floor number.
  //address2Field.focus();
}


 getRandomIntInclusive(min) {
   /*
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min +1)) + min;*/

    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < min; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;

}

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routeParamsChange.next();
  }

  private setCategoriesObservable() {
    this.categoriesObservable = this.categories.getWhere('lang', '==', this.language).pipe(
      map((categories: Category[]) => {
        return categories.sort((a: Category, b: Category) => b.createdAt - a.createdAt);
      }),
      takeUntil(this.routeParamsChange)
    );
  }

  onTitleInput() {
    this.slug = slugify(this.title).substr(0, 50);
  }

  onTarifInput(name:string) {
    return slugify(name).substr(0, 50);
  }

  addCategory(event: Event) {
    const target = event.target as any;
    target.disabled = true;
    this.categories.add({
      label: this.newCategory,
      slug: slugify(this.newCategory),
      lang: this.language
    }).catch((error: Error) => {
      this.alert.error(error.message);
    }).finally(() => {
      this.newCategory = '';
    });
  }

  /*onCategoryCheck(category: Category, event: Event|any) {
    if (event.target.checked) {
      this.checkedCategories.push(category.id);
    } else {
      const index = this.checkedCategories.indexOf(category.id);
      if (index !== -1) {
        this.checkedCategories.splice(index, 1);
      }
    }
  }*/

  onImageChange(event: Event) {
    this.image = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageSrc = reader.result;
    };
    reader.readAsDataURL(this.image);
  }

  onLogoChange(event: Event) {
    this.logo = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.logoSrc = reader.result;
    };
    reader.readAsDataURL(this.logo);
  }

  savePost(event: Event) {
    const target = event.target as any;
    const startLoading = () => {
      target.isLoading = true;
      this.isSubmitButtonsDisabled = true;
    };
    const stopLoading = () => {
      target.isLoading = false;
      this.isSubmitButtonsDisabled = false;
    };
    startLoading();
    // Check if post slug is duplicated
    this.posts.isSlugDuplicated(this.slug, this.language, this.id).then((duplicated: boolean) => {
      if (duplicated) {
        // Warn user about post slug
        this.alert.warning(this.i18n.get('PostSlugAlreadyExists'), false, 5000);
        stopLoading();
      } else {
        // Edit post
        const data: Post = {
          lang: this.language,
          title: this.title,
          slug: this.slug,
          redirection:this.redirection,
          date: new Date(this.date).getTime(),
          content: this.editor.root.innerHTML,
          status: this.status,
          categories: this.checkedCategories
        };
        if (this.image) {
          data.image = this.image;
        }
        this.posts.edit(this.id, data).then(() => {
          this.alert.success(this.i18n.get('PostSaved'), false, 5000, true);
          this.navigation.redirectTo('notifications', 'list');
        }).catch((error: Error) => {
          this.alert.error(error.message);
        }).finally(() => {
          stopLoading();
        });
      }
    }).catch((error: Error) => {
      this.alert.error(error.message);
      stopLoading();
    });
  }

}
