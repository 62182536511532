import { Component, OnInit } from '@angular/core';
import { I18nService } from '../../services/i18n.service';
import { AlertService } from '../../services/alert.service';
import { SettingsService } from '../../services/settings.service';
import { NavigationService } from '../../services/navigation.service';
import { Language } from '../../models/language.model';
import { CurrentUserService } from '../../services/current-user.service';
import { ConfigService } from '../../services/collections/config.service';

@Component({
  selector: 'fa-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  i18nLanguage: string;
  i18nKey: string;
  solutionName:string = 'VTSolution'
  isMultiOrderPlace:boolean = false
  isClickAndCollect:boolean = false
  isDelivery:boolean = false
  isSeparateOrder:boolean = false
  isTVAOrder:boolean = false
  isMaintenanceInit:boolean = false
  isMaintenance:boolean = false
  isMaintenanceClient:boolean = false
  isMaintenanceStaff:boolean = false

  constructor(
    public settings: SettingsService,
    public navigation: NavigationService,
    private i18n: I18nService,
    private alert: AlertService,
    private currentUser: CurrentUserService,
    private config: ConfigService
  ) { }

  ngOnInit() {
    this.getConfig()
  }

  saveChanges(event: Event) {
    event.preventDefault();
    this.settings.save();
    this.saveConfig();
    this.i18n.setLanguage(this.settings.language);
    this.alert.success(this.i18n.get('SettingsSaved'), true);
    this.saveConfig()
    //window.location.reload();
  }

  onI18nLanguageInput() {
    if (!this.i18nKey || this.i18nKey.length < 2) {
      this.i18nKey = this.i18nLanguage.substr(0, 2).toLowerCase();
    }
  }

  addSupportedLanguage() {
    this.settings.supportedLanguages.push({
      label: this.i18nLanguage,
      key: this.i18nKey,
      isActive: true,
      isRemovable: true
    });
    this.i18nLanguage = this.i18nKey = '';
  }

  removeSupportedLanguage(lang: Language, index: number) {
    const activeSupportedLanguages = this.settings.getActiveSupportedLanguages();
    if ((activeSupportedLanguages && activeSupportedLanguages.length > 1) || !lang.isActive) {
      this.settings.supportedLanguages.splice(index, 1);
    }
  }

  onSupportedLanguageCheckboxClick(event: Event, lang: Language) {
    const activeSupportedLanguages = this.settings.getActiveSupportedLanguages();
    if (activeSupportedLanguages.length < 2 && lang.isActive) {
      event.preventDefault();
    }
  }

  getConfig() {
    const machin = this.config.getConfigParameter().subscribe((resultConfig) => {
      if(resultConfig.exists) {
        const data = resultConfig.data()
        this.solutionName = data.name
        this.isMultiOrderPlace = data.isMultiOrderPlace
        this.isClickAndCollect = data.isClickAndCollect
        this.isDelivery = data.isDelivery
        this.isSeparateOrder = data.isSeparateOrder
        this.isTVAOrder = data.isTVAOrder
        this.isMaintenanceInit = data.isMaintenance
        this.isMaintenance = data.isMaintenance
        this.isMaintenanceClient = data.isMaintenanceClient
        this.isMaintenanceStaff = data.isMaintenanceStaff
        console.log(data)
      }
    })
  }

  saveConfig() {
    this.config.setConfigParameter(
      {
        name:this.solutionName,
        isMultiOrderPlace: this.isMultiOrderPlace,
        isClickAndCollect: this.isClickAndCollect,
        isDelivery: this.isDelivery,
        isSeparateOrder: this.isSeparateOrder,
        isTVAOrder: this.isTVAOrder,
        isMaintenance: this.isMaintenance,
        isMaintenanceClient: this.isMaintenanceClient,
        isMaintenanceStaff: this.isMaintenanceStaff

      }
      ).then((result) => {
        window.location.reload();
      }).catch((error) => {
        console.log(error)
        window.location.reload();
      })
  }

}
