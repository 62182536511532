import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Post, PostTranslation, PostStatus } from '../../models/collections/post.model';
import { now, guid, isFile } from '../../helpers/functions.helper';
import { StorageService } from '../storage.service';
import { map, take, mergeMap } from 'rxjs/operators';
import { of, merge, Observable } from 'rxjs';
import { getEmptyImage, getLoadingImage } from '../../helpers/assets.helper';
import { SettingsService } from '../settings.service';
import { Language } from '../../models/language.model';
import { UsersService } from './users.service';
import { QueryFn } from '@angular/fire/firestore';
import { DocumentTranslationsService } from './abstract/document-translations.service';

@Injectable()
export class DevicesService extends DocumentTranslationsService {

  private allStatus: object = {};
  private statusColors: object = {
    draft: 'warning',
    published: 'success',
    trash: 'danger',
  };
  private imagesCache: object = {};

  constructor(
    protected db: DatabaseService,
    private storage: StorageService,
    private settings: SettingsService,
    private users: UsersService
  ) {
    super(db, 'postTranslations');
    Object.keys(PostStatus).forEach((key: string) => {
      this.allStatus[PostStatus[key]] = key;
    });
  }

  getAllStatus() {
    return this.allStatus;
  }

  getAllStatusWithColors() {
    return { labels: this.allStatus, colors: this.statusColors };
  }

  getStatus(statusKey: string) {
    return this.allStatus[statusKey];
  }

  

  get(id: string) {
    return this.db.getDocument('devices', id).pipe(mergeMap(async (post: Post) => {
      const translations = await this.getTranslations(post.translationId).pipe(take(1)).toPromise();
      post.id = id;
      post.translations = translations;
      return post;
    }));
  }


  private pipePosts(postsObservable: Observable<Post[]>) {
    return postsObservable.pipe(mergeMap(async (posts: Post[]) => {
      const activeSupportedLanguages = this.settings.getActiveSupportedLanguages().map((lang: Language) => lang.key);
      //posts.forEach((post: Post) => { // forEach loop doesn't seems to work well with async/await
      for (let post of posts) {
        // console.log(post);
       // post.translations = await this.getTranslations(post.translationId).pipe(take(1)).toPromise();
        // console.log(post.translations);
        //const postLanguages = Object.keys(post.translations);
       
        post.author = post.createdBy ? this.users.getFullName(post.createdBy) : of(null);
       // post.isTranslatable = !activeSupportedLanguages.every((lang: string) => postLanguages.includes(lang));
      }
      //});
      return posts;
    }));
  }

  getAll() {
    return this.pipePosts(this.db.getCollection('devices'));
  }

  getWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string, applyPipe: boolean = false) {
    return this.getWhereFn(ref => ref.where(field, operator, value), applyPipe);
  }

  getWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('devices', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }

  getSessionsWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('devices', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }



  countAll() {
    return this.db.getDocumentsCount('devices');
  }


  countWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('devices', queryFn);
  }

  countSessionsWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('devices', queryFn);
  }

  countWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string) {
    return this.countWhereFn(ref => ref.where(field, operator, value));
  }

}
