import { Component, OnInit, OnDestroy } from '@angular/core';
import { PostsService } from '../../services/collections/posts.service';
import { SessionsService } from '../../services/collections/sessions.service';
import { PagesService } from '../../services/collections/pages.service';
import { CustomersService } from '../../services/collections/customers.service';
import { UsersService } from '../../services/collections/users.service';
import { TranslationsService } from '../../services/collections/translations.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { Post, PostStatus } from '../../models/collections/post.model';
import { Session, SessionStatus } from '../../models/collections/sessions.model'
import { Language } from '../../models/language.model';
import { Category } from '../../models/collections/category.model';
import { SettingsService } from '../../services/settings.service';
import { CategoriesService } from '../../services/collections/categories.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { initPieChart, initBarChartSimple, initBarChart, initBarChartMulti } from '../../helpers/charts.helper';
import { I18nService } from '../../services/i18n.service';
import { CurrentUserService } from '../../services/current-user.service';
import { FilterPipe } from 'w-ng5';
import { analytics, firestore } from 'firebase';
import * as moment from 'moment';
import { VersionService } from '../../services/version.service';

moment.locale('FR');

type PostByStatus = {
  label: string,
  count: number,
  //percentage: number
};

type SessionByStatus = {
  label: string,
  count: number,
  //percentage: number
};

import { DatePipe } from '@angular/common';

@Component({
  selector: 'fa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  loading:boolean = true
  loadingAllDates:boolean = true
  statistics: { posts?: number, sessions?: number, products?: number, sessionPending?:number, customers?: number, pages?: number, comments?: number, users?: number, translations?: number } = {};
  latestPosts: Observable<Post[]>;
  latestSessions: Observable<Session[]>;
  postsLanguage: string;
  sessionsStatus: string;
  postsByStatus: Observable<PostByStatus[]>;
  postsByStatusLanguage: string;
  sessionsByStatus: Observable<SessionByStatus[]>;
  languages: Language[];
  allPostsStatus: { labels: object, colors: object };
  allSessionsStatus: { labels: object, colors: object };
  allPostsCategories: Category[] = [];
  private subscription: Subscription = new Subscription();
  private postsLanguageChange: Subject<void> = new Subject<void>();
  private SessionStatusChange: Subject<void> = new Subject<void>();
  private postsByStatusLanguageChange: Subject<void> = new Subject<void>();
  private listPlacesName:Post[] = [];
  private totalOrder:number = 0
  isOrders:boolean = false
  rapportAllSubscribe:any
  rapportDateSubscribe:any
  userPlaces:any = this.currentUser.get()['places']
  rewardDaysStats = 7
  rewardPaymentsStats = 7
  rewardPlacesStats = 7
  bestPlace:string;
  bestDate:string;
  bestTime:string
  bestPaymentType:string;
  isPlaces:boolean = true
  optionCountUp:any = {suffix:'€', enableScrollSpy:false}
  optionCountUp2:any = {suffix:' produits vendus', enableScrollSpy:false}
  countCustomersActive:any = 0
  lastUpdateGlissPASS:any
  fromLastUpdateGlissPASS:any
  allOrdersGlissPASS:any
  allMoneyGlissPASS:any

  bestAllPlace:any = {place: "-", value:0}
  bestAllPlaceForGlissPASS:any = {place: "-", value:0}
  bestAllDate:any = {date: "-", value:0}
  bestAllMoneyDate:any = {date: "-", value:0}
  bestAllMoneyDateGlissPASS:any = {date: "-", value:0}
  bestAllHour:any = {hour: "-", value:0}
  bestAllPaymentType:any = {paymentType: "-", value:0}

  allOrders:number
  allProducts:number
  allMoney:number
  itemsByHour:any
  paymentType:any
  allPlaces:any = []
  allPlacesGlissPASS:any = []
  allDatePlaces:any = []
  allDateGlissPASS:any = []
  lastUpdate:any
  fromLastUpdate:any

  lastUpAndDown:number = 0

  isListAllPlaces:boolean = false

  cities:any = [
    {
        id: 1,
        name: 'Aucun',
        place: 'none',
        avatar: '//www.gravatar.com/avatar/b0d8c6e5ea589e6fc3d3e08afb1873bb?d=retro&r=g&s=30 2x'
    },
];

  selectedCity = this.cities[0].name;
  selectedSaison: string = localStorage.getItem('selectedSaison') || "";
  currentYear = new Date().getFullYear();
  nextYear = this.currentYear + 1;

  constructor(
    private posts: PostsService,
    private sessions: SessionsService,
    private pages: PagesService,
    private customers: CustomersService,
    private users: UsersService,
    private translations: TranslationsService,
    private categories: CategoriesService,
    private settings: SettingsService,
    public navigation: NavigationService,
    public currentUser: CurrentUserService,
    private i18n: I18nService,
    private pipe: FilterPipe,
    private version: VersionService
  ) { }

  async ngOnInit() {

    // Get statistics
    this.getStatistics();
    // Get languages
    this.languages = this.settings.getActiveSupportedLanguages();
    this.postsLanguage = '*';//this.languages[0].key;
    this.sessionsStatus = 'succeeded';//this.languages[0].key;
    this.postsByStatusLanguage = '*';//this.languages[0].key;
    // Get all posts status
      this.allPostsStatus = this.posts.getAllStatusWithColors();
      //this.allSessionsStatus = this.sessions.getAllStatusWithColors();
    // Get all posts categories
    this.subscription.add(
      this.categories.getAll().pipe(map((categories: Category[]) => {
        const allCategories: Category[] = [];
        categories.forEach((category: Category) => {
          allCategories[category.id] = category;
        });
        //console.log('categories', allCategories);
        return allCategories;
      })).subscribe((categories: Category[]) => {
        this.allPostsCategories = categories;
      })
    );
    const resultUser = await this.currentUser.get()
    if(resultUser.places.length > 0) {
    // Get latest posts
      this.getLatestPosts();
    }

    if(resultUser.places.length > 0) {
      //this.getLatestsOrders();
    } else {
      this.isPlaces = false
    }

    this.version.getValue$().subscribe((value) => {
      this.selectedSaison = value;
    });

  }

 async ngOnDestroy() {
    const resultUser = await this.currentUser.get()
    if(resultUser.places.length > 1) {
    this.subscription.unsubscribe();
    this.postsLanguageChange.next();
    this.SessionStatusChange.next()
    this.postsByStatusLanguageChange.next();
    this.rapportAllSubscribe.unsubscribe();
    this.rapportDateSubscribe.unsubscribe();
    }
  }

  private async getStatistics() {
    this.statistics.posts = this.userPlaces.length;
    this.statistics.customers = await this.customers.countAll();
    if (this.currentUser.isAdmin()) {
      //this.statistics.users = await this.users.countAll();
    }
  }


  private async getLatestPosts() {
    this.cities = []
    var i = 0
    const resultUser = await this.currentUser.get()
    this.latestPosts = this.posts.getWhereFn(ref => {
      let query: any = ref;
      //query = query.where(firestore.FieldPath.documentId(), 'in', resultUser.places);
      if (this.postsLanguage !== '*') {
        query = query.where('categories', 'array-contains', this.postsLanguage);
      }
      return query;
    }, true).pipe(
      map((posts: Post[]) => {
        // console.log(posts);
        const allCategories2 = [];
        posts.forEach((post: Post) => {
            i = i + 1;
            if (resultUser.places.includes(post.id)) {
                this.cities.push({
                    id: i,
                    place: post.id,
                    name: post.title,
                    avatar: post.image['url']
                });
                console.log(post.id);
                allCategories2[post.id] = post['title'];
            }
        });

        this.listPlacesName = allCategories2;
        this.selectedCity = this.cities[0].name;

        if (this.cities.length === 1) {
            this.navigation.redirectTo('posts', 'stats', this.cities[0].place);
        } else {
            this.getAllRapports();
        }

        return posts.sort((a: Post, b: Post) => b.createdAt - a.createdAt);
    }),
      takeUntil(this.postsLanguageChange)
    );
  }



  async  getContentById(ids, path) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !path) return [];

    const collectionPath = firestore().collection(path);
    const batches = [];

    while (ids.length) {
      // firestore limits batches to 10
      const batch = ids.splice(0, 10);

      // add the batch request to to a queue
      batches.push(
        collectionPath
          .where(
            firestore.FieldPath.documentId(),
            'in',
            [...batch]
          )
          .get()
          .then(results => results.docs.map(result => ({ /* id: result.id, */ ...result.data() }) ))
      )
    }

    // after all of the data is fetched, return it
    return Promise.all(batches)
      .then(content => content);
  }




    async getAllRapports() {

      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats
      ////////////////
      this.rapportAllSubscribe = this.sessions.getAllRapports().pipe(take(1)).subscribe((allRapports) => {

        this.allPlaces = []
        this.loading = false
        allRapports.forEach(element => {
          this.isOrders = true
          if(element.id === "all") {
            const datePipe = new DatePipe('en-US');
            this.lastUpdate = datePipe.transform(element['lastUpdate']*1000, 'dd-MM-yyyy à H:mm:ss')
            this.fromLastUpdate = moment(element['lastUpdate']*1000).fromNow()
            this.allOrders = element['orders']
            this.allProducts = element['products']
            this.allMoney = element['money']/100
            this.itemsByHour = element['ItemsByHour']
            this.paymentType = element['ItemsByPaymentType']
          } else {
            this.allPlaces.push(element)
            //Get Place where GlissPASS is used
            Object.keys(element['ItemsByPaymentType']).forEach(async(key: string) => {
              if(key === "card") {
                this.allPlacesGlissPASS.push({place:element.id, type:key, count: element['ItemsByPaymentType'][key].products, money: element['ItemsByPaymentType'][key].money})
              }
            })
          }
        });

        //Get Stats By Hour
        const allHoursLabels = Object.keys(this.itemsByHour).map((key: string) => key + 'H');
        const allHoursData = Object.keys(this.itemsByHour).map((key: string) => this.itemsByHour[key].products);
        //const allHoursDataBis = Object.keys(this.itemsByHour).map((key: string) => this.itemsByHour[key].orders);
        //Get Best Hour
        const bestHour = Math.max(...allHoursData);
        const indexHour = allHoursData.indexOf(bestHour);
        this.bestAllHour = {hour: allHoursLabels[indexHour], value: allHoursData[indexHour]};
        //Create Chart Hour
        this.getGraphStatsAllByHour({}, allHoursLabels, allHoursData)

         //Get Stats By Payment Type
         const allPaymentTypeLabels = Object.keys(this.paymentType).map((key: string) => this.i18n.get(key));
         const allPaymentTypeData = Object.keys(this.paymentType).map((key: string) => this.paymentType[key].products);
         const allPaymentTypeDataBis = Object.keys(this.paymentType).map((key: string) => this.paymentType[key].orders);
         //Get Best Payment Type
         const bestPaymentType = Math.max(...allPaymentTypeData);
         const indexPaymentType = allPaymentTypeData.indexOf(bestPaymentType);
         this.bestAllPaymentType = {paymentType: allPaymentTypeLabels[indexPaymentType], value: allPaymentTypeData[indexPaymentType]};
         //Create Chart Payment Type
         this.getGraphStatsAllByPaymentType(allPaymentTypeDataBis, allPaymentTypeLabels, allPaymentTypeData)


        //Get Stats By Place
        const allPlaceLabel = this.allPlaces.map(ref => this.getPlaceName(ref.id)).concat();
        const allPlaceData = this.allPlaces.map(ref => ref.products).concat();
        const allPlaceDataBis = this.allPlaces.map(ref => ref.orders).concat();
        //Get Best Place
        const bestPlace = Math.max(...allPlaceData);
        const index = allPlaceData.indexOf(bestPlace);
        this.bestAllPlace = {place: allPlaceLabel[index], value: allPlaceData[index]};
        //Create Chart Place
        this.getGraphStatsAllByPlace(allPlaceDataBis, allPlaceLabel, allPlaceData)


        //Get Stats By GlissPASS utilisation
        const allPlaceLabelForGlissPASS = this.allPlacesGlissPASS.map(ref => this.getPlaceName(ref.place)).concat();
        const allPlaceDataForGlissPASS = this.allPlacesGlissPASS.map(ref => ref.count).concat();
        //const allPlaceDataBisForGlissPASS = this.allPlacesGlissPASS.map(ref => ref.money).concat();
        //Get Best Place
        const bestPlaceForGlissPASS = Math.max(...allPlaceDataForGlissPASS);
        const indexForGlissPASS = allPlaceDataForGlissPASS.indexOf(bestPlaceForGlissPASS);
        this.bestAllPlaceForGlissPASS = {place: allPlaceLabelForGlissPASS[indexForGlissPASS], value: allPlaceDataForGlissPASS[indexForGlissPASS]};
        //Create Chart Place
        this.getGraphStatsAllByPlaceForGlissPASS({}, allPlaceLabelForGlissPASS, allPlaceDataForGlissPASS)

      })
      /////////////////////////////////////////////////////////////////////////////////////////


      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by Date
      ////////////////////////

      this.rapportDateSubscribe = this.sessions.getAllDateRapports(Number(this.rewardDaysStats)).pipe(take(1)).subscribe(async(allDateRapports:any) => {
        this.allDatePlaces = []
        console.log('ALL RAPPORTS BY DATE', allDateRapports)
        var i = 0
        allDateRapports.reverse().forEach(element => {
          if(i>0) {
            element['lastItem'] = element.money/100 > this.lastUpAndDown/100 ? 'up':'down'
            if(element.money/100 == this.lastUpAndDown/100) {
              element['lastItem'] = "same"
            }
          } else {
              element['lastItem'] = "first"
          }
          this.allDatePlaces.push(element)
          this.lastUpAndDown = element.money
          i++
        });

        await this.delay(2000)
        this.allDatePlaces.reverse()

        //Get Stats by Date
        const allDateLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateData = this.allDatePlaces.map(ref => ref.products).concat();
        const allDateDataBis = this.allDatePlaces.map(ref => ref.orders).concat();
        //Get Best Date
        const bestPlace = Math.max(...allDateData);
        const index = allDateData.indexOf(bestPlace);
        this.bestAllDate = {date: allDateLabel[index], value:allDateData[index]};
        //Create Chart Place
        this.getGraphStatsAllByDate(allDateLabel.reverse(), allDateData.reverse(), allDateDataBis)


        /////////////////////////////////////////////////////////////////////////////////////////
        //Get All Stats by Date and Money
        ////////////////////////
        //Get Stats by Date
        const allDateMoneyLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateMoneyData = this.allDatePlaces.map(ref => ref.money/100).concat();
        //Get Best Date
        const bestMoneyPlace = Math.max(...allDateMoneyData);
        const indexMoney = allDateMoneyData.indexOf(bestMoneyPlace);
        this.bestAllMoneyDate = {date: allDateMoneyLabel[indexMoney], value: allDateMoneyData[indexMoney]};
        //Create Chart Place
        this.getGraphStatsAllByDateMoney(allDateMoneyData.reverse(), allDateMoneyLabel.reverse())

      /////////////////////////////////////////////////////////////////////////////////////////
      //this.allDatePlaces =  this.allDatePlaces.reverse()
        this.loadingAllDates = false
      })
      /////////////////////////////////////////////////////////////////////////////////////////







      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by GLISSPASS Date
      ////////////////////////
      this.rapportDateSubscribe = this.sessions.getAllDateRapportsGlissPASS(Number(this.rewardDaysStats)).pipe(take(1)).subscribe(async(allDateRapports:any) => {
        this.allDateGlissPASS = []
        console.log('ALL GLISSPASS RAPPORTS BY DATE', allDateRapports)
        var i = 0
        allDateRapports.reverse().forEach(element => {
          if(i>0) {
            element['lastItem'] = element.money/100 > this.lastUpAndDown/100 ? 'up':'down'
            if(element.money/100 == this.lastUpAndDown/100) {
              element['lastItem'] = "same"
            }
          } else {
              element['lastItem'] = "first"
          }
          this.allDateGlissPASS.push(element)
          this.lastUpAndDown = element.money
          i++
        });

        await this.delay(2000)
        this.allDateGlissPASS.reverse()

        /////////////////////////////////////////////////////////////////////////////////////////
        //Get All Stats by Date and Money
        ////////////////////////
        //Get Stats by Date
        const allDateMoneyLabelGlissPASS = this.allDateGlissPASS.map(ref => ref.id).concat();
        const allDateMoneyDataGlissPASS = this.allDateGlissPASS.map(ref => ref.money/100).concat();
        //Get Best Date
        const bestMoneyGlissPASS = Math.max(...allDateMoneyDataGlissPASS);
        const indexMoneyGlissPASS = allDateMoneyDataGlissPASS.indexOf(bestMoneyGlissPASS);
        this.bestAllMoneyDateGlissPASS = {date: allDateMoneyLabelGlissPASS[indexMoneyGlissPASS], value: allDateMoneyDataGlissPASS[indexMoneyGlissPASS]};
        //Create Chart Place
        this.getGraphStatsAllByDateMoneyGlissPASS(allDateMoneyDataGlissPASS.reverse(), allDateMoneyLabelGlissPASS.reverse())

      /////////////////////////////////////////////////////////////////////////////////////////
      //this.allDateGlissPASS =  this.allDateGlissPASS.reverse()
      //this.loadingAllDates = false
      })
      /////////////////////////////////////////////////////////////////////////////////////////


     /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by GLISSPASS Date
      ////////////////////////
      this.rapportAllSubscribe = this.sessions.getAllRapportsGlissPASS().pipe(take(1)).subscribe((allRapports) => {
        allRapports.forEach(element => {
          if(element.id === "all") {
            const datePipe = new DatePipe('en-US');
            this.lastUpdateGlissPASS = datePipe.transform(element['lastUpdate']*1000, 'dd-MM-yyyy à H:mm:ss')
            this.fromLastUpdateGlissPASS = moment(element['lastUpdate']*1000).fromNow()
            this.allOrdersGlissPASS = element['orders']
            this.allMoneyGlissPASS = element['money']/100
          }

        })

      })
      /////////////////////////////////////////////////////////////////////////////////////////

      //Get active customers
      await this.sessions.countAllCustomersActive().then((result) => {
        console.log('c ok', result)
        this.countCustomersActive = result
      }).catch((error) => {
        console.log('c pas ok', error)
      })


  }



  async reloadPlaceDates(limit:number) {
    /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by Date
      ////////////////////////
      this.loadingAllDates = true
      this.rapportDateSubscribe = this.sessions.getAllDateRapports(Number(limit)).pipe(take(1)).subscribe(async(allDateRapports:any) => {
        this.allDatePlaces = []
        console.log('ALL RAPPORTS BY DATE', allDateRapports)
        var i = 0
        allDateRapports.reverse().forEach(element => {
          if(i>0) {
            element['lastItem'] = element.money/100 > this.lastUpAndDown/100 ? 'up':'down'
            if(element.money/100 == this.lastUpAndDown/100) {
              element['lastItem'] = "same"
            }
          } else {
              element['lastItem'] = "first"
          }
          this.allDatePlaces.push(element)
          this.lastUpAndDown = element.money
          i++
        });

        await this.delay(2000)
        this.allDatePlaces.reverse()

        //this.allDatePlaces = allDateRapports

        //Get Stats by Date
        const allDateLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateData = this.allDatePlaces.map(ref => ref.products).concat();
        const allDateDataBis = this.allDatePlaces.map(ref => ref.orders).concat();
        //Get Best Date
        const bestPlace = Math.max(...allDateData);
        const index = allDateData.indexOf(bestPlace);
        this.bestAllDate = {date: allDateLabel[index], value:allDateData[index]};
        //Create Chart Place
        this.getGraphStatsAllByDate(allDateLabel.reverse(), allDateData.reverse(), allDateDataBis.reverse())


        /////////////////////////////////////////////////////////////////////////////////////////
        //Get All Stats by Date and Money
        ////////////////////////
        //Get Stats by Date
        const allDateMoneyLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateMoneyData = this.allDatePlaces.map(ref => ref.money/100).concat();
        //Get Best Date
        const bestMoneyPlace = Math.max(...allDateMoneyData);
        const indexMoney = allDateMoneyData.indexOf(bestMoneyPlace);
        this.bestAllMoneyDate = {date: allDateMoneyLabel[indexMoney], value: allDateMoneyData[indexMoney]};
        //Create Chart Place
        this.getGraphStatsAllByDateMoney(allDateMoneyData.reverse(), allDateMoneyLabel.reverse())

      /////////////////////////////////////////////////////////////////////////////////////////
      this.loadingAllDates = false
      })
      /////////////////////////////////////////////////////////////////////////////////////////




      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by GLISSPASS
      ////////////////////////

      this.rapportDateSubscribe = this.sessions.getAllDateRapportsGlissPASS(Number(this.rewardDaysStats)).pipe(take(1)).subscribe(async(allDateRapports:any) => {
        this.allDateGlissPASS = []
        console.log('ALL GLISSPASS RAPPORTS BY DATE', allDateRapports)
        var i = 0
        allDateRapports.reverse().forEach(element => {
          if(i>0) {
            element['lastItem'] = element.money/100 > this.lastUpAndDown/100 ? 'up':'down'
            if(element.money/100 == this.lastUpAndDown/100) {
              element['lastItem'] = "same"
            }
          } else {
              element['lastItem'] = "first"
          }
          this.allDateGlissPASS.push(element)
          this.lastUpAndDown = element.money
          i++
        });

        await this.delay(2000)
        this.allDateGlissPASS.reverse()

        /////////////////////////////////////////////////////////////////////////////////////////
        //Get All Stats by Date and Money
        ////////////////////////
        //Get Stats by Date
        const allDateMoneyLabelGlissPASS = this.allDateGlissPASS.map(ref => ref.id).concat();
        const allDateMoneyDataGlissPASS = this.allDateGlissPASS.map(ref => ref.money/100).concat();
        //Get Best Date
        const bestMoneyGlissPASS = Math.max(...allDateMoneyDataGlissPASS);
        const indexMoneyGlissPASS = allDateMoneyDataGlissPASS.indexOf(bestMoneyGlissPASS);
        this.bestAllMoneyDateGlissPASS = {date: allDateMoneyLabelGlissPASS[indexMoneyGlissPASS], value: allDateMoneyDataGlissPASS[indexMoneyGlissPASS]};
        //Create Chart Place
        this.getGraphStatsAllByDateMoneyGlissPASS(allDateMoneyDataGlissPASS.reverse(), allDateMoneyLabelGlissPASS.reverse())

      /////////////////////////////////////////////////////////////////////////////////////////
      //this.allDateGlissPASS =  this.allDateGlissPASS.reverse()
      //this.loadingAllDates = false
      })
      /////////////////////////////////////////////////////////////////////////////////////////

  }

    getGraphStatsAllByPaymentType(allPlaceLabel, allPlaceData, allPlaceDataBis) {
      $('#stats-by-paymentType').remove();
      $('#graph-stats-by-paymentType').append('<canvas height="260px" id="stats-by-paymentType" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
      setTimeout(() => { // setTimeout used to wait for canvas html element to render
       initBarChartMulti('#stats-by-paymentType', allPlaceLabel, allPlaceData, allPlaceDataBis, 'bar');
      }, 0);
   }

   getGraphStatsAllByHour(allPlaceLabel, allPlaceData, allPlaceDataBis) {
    $('#stats-by-hour').remove();
    $('#graph-stats-by-hour').append('<canvas height="260px" id="stats-by-hour" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
    setTimeout(() => { // setTimeout used to wait for canvas html element to render
     initBarChartMulti('#stats-by-hour', allPlaceLabel, allPlaceData, allPlaceDataBis, 'line');
    }, 0);
 }

    getGraphStatsAllByPlace(allPlaceLabel, allPlaceData, allPlaceDataBis) {
      $('#stats-by-place').remove();
      $('#graph-stats-by-place').append('<canvas height="260px" id="stats-by-place" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
      setTimeout(() => { // setTimeout used to wait for canvas html element to render
       initBarChartMulti('#stats-by-place', allPlaceLabel, allPlaceData, allPlaceDataBis, 'bar');
      }, 0);
   }

   getGraphStatsAllByPlaceForGlissPASS(allPlaceLabel, allPlaceData, allPlaceDataBis) {
    $('#stats-by-place-glisspass').remove();
    $('#graph-stats-by-place-glisspass').append('<canvas height="260px" id="stats-by-place-glisspass" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
    setTimeout(() => { // setTimeout used to wait for canvas html element to render
     initBarChartMulti('#stats-by-place-glisspass', allPlaceLabel, allPlaceData, allPlaceDataBis, 'bar');
    }, 0);
 }

    getGraphStatsAllByDate(allDateLabel, allDateData, allDateDataBis) {
       $('#stats-by-date').remove();
       $('#graph-stats-by-date').append('<canvas height="260px" id="stats-by-date" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
       setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChartMulti('#stats-by-date', allDateDataBis, allDateLabel, allDateData, 'line');
       }, 0);
    }

    getGraphStatsAllByDateMoney(allDateLabel, allDateData) {
      $('#stats-by-date-money').remove();
      $('#graph-stats-by-date-money').append('<canvas height="260px" id="stats-by-date-money" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
      setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChartSimple('#stats-by-date-money', allDateLabel, allDateData, 'line');
      }, 0);
   }

   getGraphStatsAllByDateMoneyGlissPASS(allDateLabel, allDateData) {
    $('#stats-by-date-money-glisspass').remove();
    $('#graph-stats-by-date-money-glisspass').append('<canvas height="260px" id="stats-by-date-money-glisspass" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
    setTimeout(() => { // setTimeout used to wait for canvas html element to render
      initBarChartSimple('#stats-by-date-money-glisspass', allDateLabel, allDateData, 'line');
    }, 0);
 }



  getPlaceName(place) {
    return this.listPlacesName[place]
  }

  onPostsLanguageChange() {
    this.postsLanguageChange.next();
    this.getLatestPosts();
  }
  onSessionStatusChange() {
    this.SessionStatusChange.next();
    //this.getLatestsOrders();
  }


  doSomethingOnComplete(event) {
   // console.log(event)
  }


  onPostsByStatusLanguageChange() {
    this.postsByStatusLanguageChange.next();

    //this.getOrdersBystatus()
  }

  getIndexDatePlace(date:string) {
    return this.allDatePlaces.findIndex(object => {
      return object.id == date;
    });
  }

  getDAtePlaceByIndex(index) {
    if(index>0) {
      return this.allDatePlaces[index-1].money/100
    } else {
      return 0
    }
  }


  getListAllPlaces() {
    this.isListAllPlaces = true
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  scroll(el) {
    var x = document.getElementById(el);
    x.scrollIntoView();
  }


}
