import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { initTextEditor } from '../../../helpers/posts.helper';
import { I18nService } from '../../../services/i18n.service';
import { slugify } from '../../../helpers/functions.helper';
import { CategoriesService } from '../../../services/collections/categories.service';
import { Category } from '../../../models/collections/category.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { CartesService } from '../../../services/collections/cartes.service';
import { NavigationService } from '../../../services/navigation.service';
import { Carte, PostStatus } from '../../../models/collections/carte.model';
import { getEmptyImage } from '../../../helpers/assets.helper';
import { ActivatedRoute } from '@angular/router';

import {} from 'googlemaps'


@Component({
  selector: 'fa-posts-edit',
  templateUrl: './cartes-edit.component.html',
  styleUrls: ['./cartes-edit.component.css']
})
export class CartesEditComponent implements OnInit, OnDestroy {

  private id: string;
  title: string;
  amount: any;
  amount_paid: any;
  created:any;
  date_created:any;
  lot: string; // timestamp
  name:string;
  number: string;
  place: string;
  price: number;
  register: boolean;
  status: boolean;
  timeSession: number;
  type:string
  cardType:string
  groupMin: number;
  use: number;
  multi: boolean;

  checkedCategories: string[] = [];
  categoriesObservable: Observable<Category[]>;
  newCategory: string;
  isSubmitButtonsDisabled: boolean = false;
  allStatus: object|any = {};
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();

  constructor(
    private i18n: I18nService,
    private categories: CategoriesService,
    private alert: AlertService,
    private posts: CartesService,
    public navigation: NavigationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.allStatus = this.posts.getAllStatus();
    this.isSubmitButtonsDisabled = true;
    this.subscription.add(
      this.route.params.subscribe((params: { id: string }) => {
        // console.log(params);
        this.posts.get(params.id).pipe(take(1)).toPromise().then((post: Carte) => {
          // console.log(post);
          if (post) {
            this.id = post.id;
            this.title = post.name;
            this.lot = post.lot;
            this.created = new Date(post.created).toISOString().slice(0, 10);
            this.amount = post.amount;
            this.amount_paid = post.amount_paid
            this.number = post.number;
            this.place = post.place;
            this.price = post.price;
            //this.priceTotal = post.priceTotal;
            this.register = post.register;
            this.status = post.status;
            this.timeSession = post.timeSession;
            this.type = post.type
            this.cardType = post.cardType
            this.groupMin = post.groupMin;
            this.use = post.use;
            this.multi = post.multi;
            this.routeParamsChange.next();
            this.isSubmitButtonsDisabled = false;
          } else {
            this.navigation.redirectTo('cartes', 'list');
          }
        });
      })
    );
  }









  

// This sample uses the Places Autocomplete widget to:
// 1. Help the user select a place
// 2. Retrieve the address components associated with that place
// 3. Populate the form fields with those address components.
// This sample requires the Places library, Maps JavaScript API.
// Include the libraries=places parameter when you first load the API.
// For example: <script
// src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">






 getRandomIntInclusive(min) {
   /*
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min +1)) + min;*/

    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < min; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;

}

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routeParamsChange.next();
  }



  onTitleInput() {
    this.lot = slugify(this.title).substr(0, 50);
  }

  onTarifInput(name:string) {
    return slugify(name).substr(0, 50);
  }



  onCategoryCheck(category: Category, event: Event|any) {
    if (event.target.checked) {
      this.checkedCategories.push(category.id);
    } else {
      const index = this.checkedCategories.indexOf(category.id);
      if (index !== -1) {
        this.checkedCategories.splice(index, 1);
      }
    }
  }



  savePost(event: Event) {
    const target = event.target as any;
    const startLoading = () => {
      target.isLoading = true;
      this.isSubmitButtonsDisabled = true;
    };
    const stopLoading = () => {
      target.isLoading = false;
      this.isSubmitButtonsDisabled = false;
    };
    startLoading();
   
        // Edit post
        const data: Carte = {
          name: this.title,
        };

        this.posts.edit(this.id, data).then(() => {
          this.alert.success(this.i18n.get('PostSaved'), false, 5000, true);
          this.navigation.redirectTo('cartes', 'list');
        }).catch((error: Error) => {
          this.alert.error(error.message);
        }).finally(() => {
          stopLoading();
        });
      

  }

}
