import { Component, OnInit, OnDestroy } from '@angular/core';
import { initTextEditor } from '../../../helpers/posts.helper';
import { I18nService } from '../../../services/i18n.service';
import { slugify } from '../../../helpers/functions.helper';
import { CategoriesService } from '../../../services/collections/categories.service';
import { Category } from '../../../models/collections/category.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { PostsService } from '../../../services/collections/posts.service';
import { NavigationService } from '../../../services/navigation.service';
import { Post, PostStatus } from '../../../models/collections/post.model';
import { getEmptyImage } from '../../../helpers/assets.helper';
import { ActivatedRoute } from '@angular/router';
import { Tariff } from '../../../models/tarifs.model';
import { SessionsService } from '../../../services/collections/sessions.service';
import { initPieChart, initBarChartTarif, initBarChartSimple, initBarChartMulti, initChartTime } from '../../../helpers/charts.helper';
import { FilterPipe } from 'w-ng5';
import { DatePipe } from '@angular/common';
import { UsersService } from '../../../services/collections/users.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { jsPDF } from "jspdf";
import { Moment } from 'moment';
import { firestore } from 'firebase';
import { PromotionsService } from '../../../services/collections/promotions.service';

@Component({
  selector: 'fa-posts-edit',
  templateUrl: './posts-stats.component.html',
  styleUrls: ['./posts-stats.component.css']
})
export class PostsStatsComponent implements OnInit, OnDestroy {
  loading:boolean = true
  private id: string;
  title: string;
  editor: any;
  status: PostStatus;
  language: string;
  slug: string;
  email:string;
  phone:string;
  date: string;
  private image: File;
  imageSrc: string|ArrayBuffer;
  private logo: File;
  logoSrc: string|ArrayBuffer;
  horaires:{};
  address: {};
  mondayStart:{};
  mondayEnd:{};
  tuesdayStart:{};
  tuesdayEnd:{};
  wednesdayStart:{};
  wednesdayEnd:{};
  thursdayStart:{};
  thursdayEnd:{};
  fridayStart:{};
  fridayEnd:{};
  saturdayStart:{};
  saturdayEnd:{};
  sundayStart:{};
  sundayEnd:{};
  today:any

  resultPDFLink:any = null
  isPDFLinkCopied:boolean = false
  isPDFLinkSended:boolean = false

  lat:string;
  lng:string;
  street:string;
  streetNumber:string;
  locality:string;
  postcode:string;
  state:string;
  country:string;
  tarifBeforeAdd:any = []
  tarifs:any = []
  orders:any
  dateReport:any
  timeReport:any
  isOrders:boolean = false
  resumeSubscribe:any
  rapportTerminatedSubscribe:any
  machintruc:any = []
  truchcouette:any = []
  filterResultByEmployee:any = []
  isToday:boolean = true
  resumeOrders:any = []
  newDataOrder:any = []
  public options: any[] = [{
    name: '',
    price: '',
  }];
  orderCount:number = 0
  orderCountTotal:number = 0
  checkedCategories: string[] = [];
  categoriesObservable: Observable<Category[]>;
  newCategory: string;
  isSubmitButtonsDisabled: boolean = false;
  allStatus: object|any = {};
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();
  bestPlace:string;
  freeOrders:any
  freeOrderUsed:any
  bestPaymentType:string;
  bestTime:any 
  paymentTypes:any
  countItemsForPDF:number = 0
  ordersToRefilter:any
  timeDayStart:any
  timeDayEnd:any
  firstConnect:boolean = false
  countInvitationTerminated:number = 0
  bestDate:any
  rewardDaysStats = 7
  
  optionCountUp:any = {suffix:'€', enableScrollSpy:true}
  optionCountUp2:any = {suffix:' produits vendus', enableScrollSpy:true}
  optionCountUp3:any = {enableScrollSpy:true}
  
  rapportAllSubscribe:any
  dateReportStats:any
  bestAllPlace:any
  bestAllDate:any;
  bestAllMoneyDate:any;
  bestAllHour:any
  bestAllPaymentType:any

  allOrders:any
  allProducts:any
  allMoney:any
  itemsByHour:any
  paymentType:any
  products:any 
  allPlaces:any = []
  allDatePlaces:any = []
  allInvitations:any = []
  allInvitationCreated:number
  daydetail:any = {id:"", ItemsByPaymentType:{['chargement...']: {orders:'-', products:'-', total:'-'}}}

  theGoodPlace:any
  loadingPDF:boolean = false
  sendingPDF:boolean = false

  constructor(
    private i18n: I18nService,
    private categories: CategoriesService,
    private alert: AlertService,
    private posts: PostsService,
    public navigation: NavigationService,
    private route: ActivatedRoute,
    private sessions:SessionsService,
    private pipe: FilterPipe,
    private users: UsersService,
    private currentUser: CurrentUserService,
    private promotion:PromotionsService

  ) { 

    
  }

  ngOnDestroy() {
    //this.resumeSubscribe.unsubscribe()
    //this.rapportTerminatedSubscribe.unsubscribe()
  }



  async ngOnInit() {

    this.loading = true
    this.allStatus = this.posts.getAllStatus();
    this.isSubmitButtonsDisabled = true;
 
      this.route.params.subscribe(async(params: { id: string }) => {  this.theGoodPlace = params.id   })
        // console.log(params);
       
        await this.delay(500)
       await this.posts.get(this.theGoodPlace).pipe(take(1)).toPromise().then(async(post: Post) => {
          // console.log(post);
          if (post) {
            this.id = post.id;
            this.title = post.title;
            this.status = post.status;
            this.slug = post.slug;
            this.date = new Date(post.date).toISOString().slice(0, 10);
            this.language = post.lang;
            this.email = post.email;
            this.phone = post.phone;
            this.horaires = post.horaires;
            this.tarifBeforeAdd = post.tarifs;
            this.tarifs = post.tarifs;
            this.options = post.options;
            this.mondayStart = post.horaires['monday'].start;
            this.mondayEnd = post.horaires['monday'].end;
            this.tuesdayStart = post.horaires['tuesday'].start;
            this.tuesdayEnd = post.horaires['tuesday'].end;  
            this.wednesdayStart = post.horaires['wednesday'].start;
            this.wednesdayEnd = post.horaires['wednesday'].end;
            this.thursdayStart = post.horaires['thursday'].start;
            this.thursdayEnd = post.horaires['thursday'].end;
            this.fridayStart = post.horaires['friday'].start;
            this.fridayEnd = post.horaires['friday'].end;
            this.saturdayStart = post.horaires['saturday'].start;
            this.saturdayEnd = post.horaires['saturday'].end;
            this.sundayStart = post.horaires['sunday'].start;
            this.sundayEnd = post.horaires['sunday'].end;

            //console.log('douuu', this.currentUser.get()['email'])
            
            const datePipe = new DatePipe('en-US');
            this.timeReport = datePipe.transform(Math.floor(Date.now()), 'H:mm')
            this.dateReport = Math.floor(Date.now())
            this.today = Math.floor(Date.now()/1000)
            this.isToday = true
            this.dateReportStats = datePipe.transform(this.dateReport, 'dd-MM-yyyy')
            this.logo = null;
            this.image = null;
            this.imageSrc = getEmptyImage();
            this.logoSrc = getEmptyImage();
            if (post.image) {
              this.posts.getImageUrl(post.image as  string).pipe(take(1)).toPromise().then((imageUrl: string) => {
                this.imageSrc = imageUrl;
               // console.log('image', this.imageSrc)
              });
            }
            if (post.logo) {
              this.posts.getImageUrl(post.logo as  string).pipe(take(1)).toPromise().then((logoUrl: string) => {
                this.logoSrc = logoUrl;
              });
            }
            this.isSubmitButtonsDisabled = false;
            await this.delay(1000)
            await this.getDayRapports(post.id, this.dateReportStats)
    
          } else {
            this.navigation.redirectTo('posts', 'list');
          }
        });

    

  }


  async reload(type:string) {
    this.resultPDFLink = null
    this.loading = true
    const datePipe = new DatePipe('en-US');
    var date = ""
    if(type === "daySelect") {
      this.today = this.dateReport
      this.dateReport = new Date(this.dateReport).getTime();
      date = datePipe.transform(this.dateReport, 'dd-MM-yyyy')
    } else {
      this.today = Math.floor(Date.now()/1000)
      this.dateReport = Math.floor(Date.now())
      date = datePipe.transform(this.dateReport, 'dd-MM-yyyy')
    }
    this.dateReportStats = datePipe.transform(this.dateReport * 1000, 'dd-MM-yyyy')
    if(type === "day" || type === "daySelect") {
      this.isToday = true
      await this.getDayRapports(this.id, date)
    } else {
      this.isToday = false
      await this.getAllRapports(this.id, this.rewardDaysStats)
    }
  }


  async getDayRapports(place:string, date:string) {
      /////////////////////////////////////////////////////////////////////////////////////////
      //Get Day Stats
      ////////////////
      await this.sessions.getPlaceRapportDate(this.theGoodPlace, date).pipe(take(1)).toPromise().then(async(allRapports) => {
      //this.allOrders = allRapports['orders']
      this.allProducts = allRapports['products']
      this.allMoney = allRapports['money']/100
      this.itemsByHour = allRapports['ItemsByHour'] || allRapports['itemsByHour']
      this.paymentType = allRapports['ItemsByPaymentType']
      this.products = allRapports['items']
      this.isOrders = true
      
      //Get Stats By Hour
      const allHoursLabels = Object.keys(this.itemsByHour).map((key: string) => key + 'H'); 
      const allHoursData = Object.keys(this.itemsByHour).map((key: string) => this.itemsByHour[key].products);
      //const allHoursDataBis = Object.keys(this.itemsByHour).map((key: string) => this.itemsByHour[key].orders);
      //Get Best Hour
      const bestHour = Math.max(...allHoursData);
      const indexHour = allHoursData.indexOf(bestHour);
      this.bestAllHour = {hour: allHoursLabels[indexHour], value: allHoursData[indexHour]};

      //Get Stats By Payment Type
    //  const allPaymentTypeLabels = Object.keys(this.paymentType).map((key: string) => this.i18n.get(key)); 
    //  const allPaymentTypeData = Object.keys(this.paymentType).map((key: string) => this.paymentType[key].products);
    //  const allPaymentTypeDataBis = Object.keys(this.paymentType).map((key: string) => this.paymentType[key].orders);
      //Get Best Payment Type
    //  const bestPaymentType = Math.max(...allPaymentTypeData);
    //  const indexPaymentType = allPaymentTypeData.indexOf(bestPaymentType);
    //  this.bestAllPaymentType = {paymentType: allPaymentTypeLabels[indexPaymentType], value: allPaymentTypeData[indexPaymentType]};

      await this.delay(50)
      this.loading = false
      await this.delay(300)

      //Create Chart Hour
      this.getGraphStatsAllByHour({}, allHoursLabels, allHoursData)
      //Create Chart Payment Type
    //  this.getGraphStatsAllByPaymentType(allPaymentTypeDataBis, allPaymentTypeLabels, allPaymentTypeData)

      this.isOrders = true

    }).catch((error) => {
       console.log('No Result', error)
       this.loading = false
       this.isOrders = false
    })
    /////////////////////////////////////////////////////////////////////////////////////////

  }


  async getAllRapports(place:string, limit:number) {
/////////////////////////////////////////////////////////////////////////////////////////
    //Get All Stats
    ////////////////
    await this.sessions.getPlaceRapport(this.id).pipe(take(1)).toPromise().then(async(allRapports) => {
      this.allOrders = allRapports['orders']
      this.allProducts = allRapports['products']
      this.allMoney = allRapports['money']/100
      this.itemsByHour = allRapports['ItemsByHour'] || allRapports['itemsByHour']
      this.paymentType = allRapports['ItemsByPaymentType']
      this.products = allRapports['items']
      this.isOrders = true
      
      //Get Stats By Hour
      const allHoursLabels = Object.keys(this.itemsByHour).map((key: string) => key + 'H'); 
      const allHoursData = Object.keys(this.itemsByHour).map((key: string) => this.itemsByHour[key].products);
      //const allHoursDataBis = Object.keys(this.itemsByHour).map((key: string) => this.itemsByHour[key].orders);
      //Get Best Hour
      const bestHour = Math.max(...allHoursData);
      const indexHour = allHoursData.indexOf(bestHour);
      this.bestAllHour = {hour: allHoursLabels[indexHour], value: allHoursData[indexHour]};

      //Get Stats By Payment Type
    //  const allPaymentTypeLabels = Object.keys(this.paymentType).map((key: string) => this.i18n.get(key)); 
    //  const allPaymentTypeData = Object.keys(this.paymentType).map((key: string) => this.paymentType[key].products);
    //  const allPaymentTypeDataBis = Object.keys(this.paymentType).map((key: string) => this.paymentType[key].orders);
      //Get Best Payment Type
    //  const bestPaymentType = Math.max(...allPaymentTypeData);
    //  const indexPaymentType = allPaymentTypeData.indexOf(bestPaymentType);
    //  this.bestAllPaymentType = {paymentType: allPaymentTypeLabels[indexPaymentType], value: allPaymentTypeData[indexPaymentType]};


      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by Date
      ////////////////////////
      await this.sessions.getPlaceRapportAllDate(this.theGoodPlace, limit).pipe(take(1)).toPromise().then(async(allDateRapports) => {
        console.log('ALL RAPPORTS BY DATE', allDateRapports)
        this.allDatePlaces = allDateRapports

       
        /*this.allDatePlaces = this.allDatePlaces.sort(function (a, b) {
          // '01/03/2014'.split('/')
          // gives ["01", "03", "2014"]
          a = a.id.split('-');
          b = b.id.split('-');
          return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
        });*/
        
        //Get Stats by Date
        const allDateLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateData = this.allDatePlaces.map(ref => ref.products).concat();
        const allDateDataBis = this.allDatePlaces.map(ref => ref.orders).concat();
        //Get Best Date
        const bestPlace = Math.max(...allDateData);
        const index = allDateData.indexOf(bestPlace);
        this.bestAllDate = {date: allDateLabel[index], value:allDateData[index]};


        /////////////////////////////////////////////////////////////////////////////////////////
        //Get All Stats by Date and Money
        ////////////////////////
        //Get Stats by Date
        const allDateMoneyLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateMoneyData = this.allDatePlaces.map(ref => ref.money/100).concat();
        //Get Best Date
        const bestMoneyPlace = Math.max(...allDateMoneyData);
        const indexMoney = allDateMoneyData.indexOf(bestMoneyPlace);
        this.bestAllMoneyDate = {date: allDateMoneyLabel[indexMoney], value: allDateMoneyData[indexMoney]};

        await this.delay(200)
        this.loading = false
        await this.delay(200)

        //Create Chart Place
        this.getGraphStatsAllByDate(allDateLabel.reverse(), allDateData.reverse(), allDateDataBis.reverse())
        //Create Chart Place
        this.getGraphStatsAllByDateMoney(allDateMoneyData.reverse(), allDateMoneyLabel.reverse())
      
        //this.allDatePlaces = this.allDatePlaces.reverse()
      /////////////////////////////////////////////////////////////////////////////////////////
      }).catch((error) => {
        console.log('No Result')
      })

      await this.delay(200)
      this.loading = false
      await this.delay(200)

      //Create Chart Hour
      this.getGraphStatsAllByHour({}, allHoursLabels, allHoursData)
      //Create Chart Payment Type
    //  this.getGraphStatsAllByPaymentType(allPaymentTypeDataBis, allPaymentTypeLabels, allPaymentTypeData)

      this.isOrders = true

      }).catch((error) => {
        console.log('No Result')
        this.loading = false
        this.isOrders = false
      })
      /////////////////////////////////////////////////////////////////////////////////////////


      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Invitation Created
      ////////////////////////
      const publishedPosts = await this.promotion.getWhereFn(ref => ref.where("place", '==', place))
      .pipe(take(1)).toPromise().then(async(allRapports) => {
      return allRapports
      })
      this.allInvitationCreated = publishedPosts.reduce((sum,item) => sum + item.countCodes, 0)

      /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Invitation Used
      ////////////////////////
      this.rapportTerminatedSubscribe = this.sessions.getRapportsTerminated(place).pipe(take(1)).toPromise().then((rapp) => {
        this.allInvitations = rapp
        this.countInvitationTerminated = rapp.orders
      }).catch((error) => {
        this.countInvitationTerminated = 0
        this.allInvitations = []
      })
      
  }


  async reloadPlaceDates(place,limit:number) {
    /////////////////////////////////////////////////////////////////////////////////////////
      //Get All Stats by Date
      ////////////////////////
      await this.sessions.getPlaceRapportAllDate(this.id, Number(limit)).pipe(take(1)).toPromise().then(async(allDateRapports) => {
        console.log('ALL RAPPORTS BY DATE', allDateRapports)
        this.allDatePlaces = allDateRapports
        //Get Stats by Date
        const allDateLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateData = this.allDatePlaces.map(ref => ref.products).concat();
        //const allDateDataBis = this.allDatePlaces.map(ref => ref.orders).concat();
        //Get Best Date
        const bestPlace = Math.max(...allDateData);
        const index = allDateData.indexOf(bestPlace);
        this.bestAllDate = {date: allDateLabel[index], value:allDateData[index]};


        /////////////////////////////////////////////////////////////////////////////////////////
        //Get All Stats by Date and Money
        ////////////////////////
        //Get Stats by Date
        const allDateMoneyLabel = this.allDatePlaces.map(ref => ref.id).concat();
        const allDateMoneyData = this.allDatePlaces.map(ref => ref.money/100).concat();
        //Get Best Date
        const bestMoneyPlace = Math.max(...allDateMoneyData);
        const indexMoney = allDateMoneyData.indexOf(bestMoneyPlace);
        this.bestAllMoneyDate = {date: allDateMoneyLabel[indexMoney], value: allDateMoneyData[indexMoney]};

        await this.delay(200)
        this.loading = false
        await this.delay(200)

        //Create Chart Place
        this.getGraphStatsAllByDate(allDateLabel.reverse(), allDateData.reverse(), {})
        //Create Chart Place
        this.getGraphStatsAllByDateMoney(allDateMoneyData.reverse(), allDateMoneyLabel.reverse())
      
      /////////////////////////////////////////////////////////////////////////////////////////
      }).catch((error) => {
        console.log('No Result')
      })
  }

  
  getGraphStatsAllByPaymentType(allPlaceLabel, allPlaceData, allPlaceDataBis) {
    $('#stats-by-paymentType').remove();
    $('#graph-stats-by-paymentType').append('<canvas height="260px" id="stats-by-paymentType" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
    setTimeout(() => { // setTimeout used to wait for canvas html element to render
     initBarChartMulti('#stats-by-paymentType', allPlaceLabel, allPlaceData, allPlaceDataBis, 'bar');
    }, 100);
 }

 getGraphStatsAllByHour(allPlaceLabel, allPlaceData, allPlaceDataBis) {
  $('#stats-by-hour').remove();
  $('#graph-stats-by-hour').append('<canvas height="260px" id="stats-by-hour" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
  setTimeout(() => { // setTimeout used to wait for canvas html element to render
   initBarChartMulti('#stats-by-hour', allPlaceLabel, allPlaceData, allPlaceDataBis, 'line');
  }, 100);
}

getGraphStatsAllByDate(allDateLabel, allDateData, allDateDataBis) {
  $('#stats-by-date').remove();
  $('#graph-stats-by-date').append('<canvas height="260px" id="stats-by-date" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
  setTimeout(() => { // setTimeout used to wait for canvas html element to render
   initBarChartMulti('#stats-by-date', allDateDataBis, allDateLabel, allDateData, 'line');
  }, 0);
}

getGraphStatsAllByDateMoney(allDateLabel, allDateData) {
 $('#stats-by-date-money').remove();
 $('#graph-stats-by-date-money').append('<canvas height="260px" id="stats-by-date-money" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
 setTimeout(() => { // setTimeout used to wait for canvas html element to render
   initBarChartSimple('#stats-by-date-money', allDateLabel, allDateData, 'line');
 }, 0);
}


delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}


    exportVentesToPDF() {
     var init = 0
      var headers = this.createHeaders([
        "Nom du tarif",
        "Prix /unité",
        "Nbre de ventes",
        "Total (en euros)",
      ]);
      
      var doc = new jsPDF();
      doc.addImage("assets/images/logo.png", "JPEG", 180, 5, 25, 25);

      doc.setFontSize(18);
      doc.text(String("Rapports des ventes - " + this.title + ""), 5 ,15, null, null);

      doc.setFontSize(16);
      doc.setTextColor("blue");
      const datePipe = new DatePipe('en-US');
      if(!this.isToday) {
        doc.text(String("Complet (depuis ouverture)"), 5 ,25, null, null);
      }
      if(this.isToday) {
        doc.text(String("Le " + datePipe.transform(this.dateReport, 'dd-MM-yyyy') + ""), 5 ,25, null, null);
      }

      doc.setTextColor("black");
      doc.setFontSize(12);
      doc.text(String("TOTAL DES VENTES = " + this.allMoney + "€"), 5 ,45, null, null);

      doc.setFontSize(14);
      doc.table(5, 50, this.generateData(), headers, { autoSize: true });

      
      if(this.currentUser.isAdmin()) {
        doc.setFontSize(12);
        doc.setTextColor("gray");
        Object.keys(this.paymentType).map((key: any) => {
          doc.text(String(this.paymentType[key].orders + " commandes avec => " + this.i18n.get(key)), 5, 50 + Number(20 * this.countItemsForPDF) + init, null, null)
          //init = Number(init + 5)
        }
        );
      }
      

      

      if(this.isToday) {
        doc.save("Rapports des ventes - " + this.title + " - Du " +  datePipe.transform(this.dateReport, 'dd-MM-yyyy') + ".pdf");
      }
      if(!this.isToday) {
        doc.save("Rapports des ventes - " + this.title + " - Complet.pdf");
      }
    }

    
     createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          name: keys[i],
          prompt: keys[i],
          width: 150,
          align: "center",
          padding: 0
        });
      }
      return result;
    }

    generateData() {
      var result = [];
      const testPDF = this.products

   
    const labelspaymentType = Object.keys(testPDF).map((key: any) => {
    result.push(Object.assign({}, {
      "Nom du tarif": String(key),
      "Prix /unité": String(testPDF[key].price),
      "Nbre de ventes": String(testPDF[key].count),
      "Total (en euros)": String(testPDF[key].total + "€"),
    }))
    this.countItemsForPDF = this.countItemsForPDF + 1
  });
      return result;
    }


    redirectToPlaceStats(date) {
      this.resultPDFLink = null
      this.loading = true
      this.isToday = true
      var myDate = date.split("-");
      var newDate = new Date( myDate[2], myDate[1] - 1, myDate[0]);
      console.log(newDate.getTime());
      this.dateReport = newDate.getTime()
      this.getDayRapports(this.id, date)
    }
    

    getDatePlace(date:string) {
      return this.allDatePlaces.find(object => {
        return object.id == date;
      });
    }

    getIndexDatePlace(date:string) {
      return this.allDatePlaces.findIndex(object => {
        return object.id == date;
      });
    }

    getDAtePlaceByIndex(index) {
      if(index>0) {
        return this.allDatePlaces[index-1].money/100
      } else {
        return 0
      }
    }

    async getRapportPlacePDF(date) {
      this.isPDFLinkSended = false
      this.isPDFLinkCopied = false

      let dateStats:any
      if(date !== 'all') {
      const ok = new Date(date).getTime();
      const datePipe = new DatePipe('en-US');
        dateStats = datePipe.transform(ok, 'dd-MM-yyyy')
      } else {
        dateStats = 'all'
      }
      this.loadingPDF = true
      const result = await this.users.getRapportPlacePDF(this.id, dateStats).then((result) => {
        console.log('RESULT OF ACCOUNT LINK', result)
        this.resultPDFLink = result.data.url
      })
      this.loadingPDF = false
    }

    async copiedLink() {
      navigator.clipboard.writeText(this.resultPDFLink);
      this.isPDFLinkCopied = true
      await this.delay(2500)
      this.isPDFLinkCopied = false
    }



    async sendRapportEmail(date) {
      let dateStats:any
      if(date !== 'all') {
      const ok = new Date(date).getTime();
      const datePipe = new DatePipe('en-US');
        dateStats = datePipe.transform(ok, 'dd-MM-yyyy')
      } else {
        dateStats = 'all'
      }
      this.sendingPDF = true
      const result = await this.users.sendRapportEmail(this.id, dateStats, this.currentUser.get()['email']).then((result) => {
        console.log('EMAIL SEND', result)
        this.isPDFLinkSended = true
      })
      this.sendingPDF = false
      await this.delay(3500)
      this.isPDFLinkSended = false
    }


    truncate(str, n){
      return (str.length > n) ? str.slice(0, n-1) + '&hellip;' : str;
    };

    scroll(el: HTMLElement) {
      el.scrollIntoView();
    }


}
