import { Observable } from 'rxjs';
import { DocumentTranslation } from './document-translation';

export interface Session {
  id?: string;
  created?: number;
  lastmodified?: number;
  orderNumber?:string;
  paymentType?:string;
  cart?:any[];
  place?: string;
  paymentIntent?: {};
  status?: SessionStatus;
  total?: number;
  uid?:string
}

export enum SessionStatus {
  Succeeded = 'succeeded',
  Requires_payment_method = 'requires_payment_method',
  Terminated = 'terminated',
  Creating = 'creating'
}

export interface SessionTranslation extends DocumentTranslation { }
