import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Promotions, PostTranslation, PostStatus } from '../../models/collections/promotions.model';
import { now, guid, isFile } from '../../helpers/functions.helper';
import { StorageService } from '../storage.service';
import { map, take, mergeMap } from 'rxjs/operators';
import { of, merge, Observable } from 'rxjs';
import { getEmptyImage, getLoadingImage } from '../../helpers/assets.helper';
import { SettingsService } from '../settings.service';
import { Language } from '../../models/language.model';
import { UsersService } from './users.service';
import { QueryFn } from '@angular/fire/firestore';
import { DocumentTranslationsService } from './abstract/document-translations.service';

@Injectable()
export class PromotionsService extends DocumentTranslationsService {

  imageURL:any
  private allStatus: object = {};
  private statusColors: object = {
    draft: 'warning',
    published: 'success',
    trash: 'danger',
  };
  private imagesCache: object = {};
  freeProduits:any = []
  constructor(
    protected db: DatabaseService,
    private storage: StorageService,
    private settings: SettingsService,
    private users: UsersService
  ) {
    super(db, 'postTranslations');
    Object.keys(PostStatus).forEach((key: string) => {
      this.allStatus[PostStatus[key]] = key;
    });
  }

  getAllStatus() {
    return this.allStatus;
  }

  getAllStatusWithColors() {
    return { labels: this.allStatus, colors: this.statusColors };
  }

  getStatus(statusKey: string) {
    return this.allStatus[statusKey];
  }

  add(data: Promotions, listCodes:any, translationId?: string) {
    const post: Promotions = {
      title: data.title,
      lang: data.lang,
      slug: data.slug,
      number: data.number,
      countCodes: data.countCodes,
      listCodes: data.listCodes,
      typeCode: data.typeCode,
      freeProducts: data.freeProducts,
      isQrCode:data.isQrCode,
      place: data.place,
      placeName: data.placeName,
      state: data.state,
      date: data.date,
      image: null,
      content: data.content,
      status: data.status,
      categories: data.categories,
      createdAt: now(), // timestamp
      updatedAt: null,
      createdBy: this.db.currentUser.id,
      updatedBy: null
    };
    if (translationId && data.image && !isFile(data.image)) {
      post.image = data.image;
    }

    if(data.categories === 'invitation') {
      const commandNumber = this.generateOrderNumber()

      const newItem = []
     
      data.freeProducts.forEach(element => {
        for (let i = 0; i < element.count; i++) {
          newItem.push({
          id: newItem.length + 1,
          name: '1 entrée', //element.name,
          type: "normal",
          price: 0,
          tva: 0,
          priceHT: 0,
          calculTVA: 0,
          number: this.generateOrderNumber(),
          sum: 1,
          created: Math.floor(Date.now()/1000),
          used: null,
          place: data.place,
          placeName: data.placeName,
          status: 'ready'
      })
    }
    })
  

    return new Promise((resolve, reject) => {
      listCodes.forEach(element => {
        
        const dataOrder = {
          created: Math.floor(Date.now()),
          lastmodified: Math.floor(Date.now()),
          total: 0, 
          paymentIntent: {status:'succeeded'},
          cart: newItem,
          description: 'Commande N° ' + element,
          orderNumber: element,
          place: data.place,
          placesMulti: [],
          paymentType:'free',
          status:'succeeded',
          uid: this.db.currentUser.id
      }
  
        this.db.addDocument('orders', dataOrder).then((doc: any) => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });

    });
    this.db.addDocument('promotions', post)
    
    });
  }
else {
    return new Promise((resolve, reject) => {

      listCodes.forEach(element => {
        post['number'] = element
        this.db.addDocument('promotions', post).then((doc: any) => {
            resolve();
            }).catch((error: Error) => {
              reject(error);
            });
        })


        })

  }

  }

  translate(data: Promotions) {
    return this.add(data, [], data.translationId);
  }

  private uploadImage(id: string, imageFile: File) {
    return new Promise((resolve, reject) => {
      if (imageFile && isFile(imageFile)) {
        var imageGuid = guid()
        const imageName = imageGuid + '.' + imageFile.name.split('.').pop();
        const imageNameResized = imageGuid + '_800x800' + '.' + imageFile.name.split('.').pop();
        const imagePath = `promotions/${id}/${imageName}`;
        const imagePathResized = `promotions/${id}/thumbs/${imageNameResized}`;
        const imgUrlResized = `https://storage.googleapis.com/glisspass.appspot.com/promotions/${id}/thumbs/${imageNameResized}`
        this.storage.upload(imagePath, imageFile).then(async (resulttt) => {
          
         await resulttt.ref.getDownloadURL().then(imgUrl => {
          this.db.setDocument('promotions', id, { image: imagePath, imagePathResized: imagePathResized, imgUrl: imgUrl, imgUrlResized: imgUrlResized}).then(() => {
            resolve();
          }).catch((error: Error) => {
            reject(error);
          }); 
          });
           

        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  private uploadLogo(id: string, imageFile: File) {
    return new Promise((resolve, reject) => {
      if (imageFile && isFile(imageFile)) {
        const imageName = guid() + '.' + imageFile.name.split('.').pop();
        const imagePath = `promotions/${id}/${imageName}`;
        this.storage.upload(imagePath, imageFile).then(() => {
          this.db.setDocument('promotions', id, { logo: imagePath }).then(() => {
            resolve();
          }).catch((error: Error) => {
            reject(error);
          });
        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  get(id: string) {
    return this.db.getDocument('promotions', id).pipe(mergeMap(async (post: Promotions) => {
     // const translations = await this.getTranslations(post.translationId).pipe(take(1)).toPromise();
      post.id = id;
     // post.translations = translations;
      return post;
    }));
  }

  getTranslationLanguages(post: Promotions) {
    const postLanguages = Object.keys(post.translations);
    return this.settings.getActiveSupportedLanguages().filter((lang: Language) => postLanguages.indexOf(lang.key) === -1);
  }

  getImageUrl(imagePath: string) {
    if (this.imagesCache[imagePath]) {
      return of(this.imagesCache[imagePath]);
    } else {
      return this.storage.get(imagePath).getDownloadURL().pipe(map((imageUrl: string) => {
        this.imagesCache[imagePath] = imageUrl;
        return imageUrl;
      }));
    }
  }

  getImageUrlNotification(imagePath: string) {
      return this.storage.get(imagePath).getDownloadURL().pipe(map((imageUrl: string) => {
        this.imagesCache[imagePath] = imageUrl;
        return imageUrl;
      }));
  }

  private pipePosts(postsObservable: Observable<Promotions[]>) {
    return postsObservable.pipe(mergeMap(async (posts: Promotions[]) => {
      const activeSupportedLanguages = this.settings.getActiveSupportedLanguages().map((lang: Language) => lang.key);
      //posts.forEach((post: Post) => { // forEach loop doesn't seems to work well with async/await
      for (let post of posts) {
        // console.log(post);
        //post.translations = await this.getTranslations(post.translationId).pipe(take(1)).toPromise();
        // console.log(post.translations);
       // const postLanguages = Object.keys(post.translations);
        post.image = {
          path: post.image,
          url: post.image ? merge(of(getLoadingImage()), this.getImageUrl(post.image as string)) : of(getEmptyImage())
        };
        post.author = post.createdBy ? this.users.getFullName(post.createdBy) : of(null);
        //post.isTranslatable = !activeSupportedLanguages.every((lang: string) => postLanguages.includes(lang));
      }
      //});
      return posts;
    }));
  }

  getAll() {
    return this.pipePosts(this.db.getCollection('promotions'));
  }

  getWhere(field: any, operator: firebase.firestore.WhereFilterOp, value: string, applyPipe: boolean = false) {
    return this.getWhereFn(ref => ref.where(field, operator, value), applyPipe);
  }

  getWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('promotions', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }

  getSessionsWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('sessions', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }

  edit(id: string, data: Promotions) {
    const post: Promotions = {
      title: data.title,
      lang: data.lang,
      slug: data.slug,
      number: data.number,
      place: data.place,
      countCodes: data.countCodes,
      freeProducts: data.freeProducts,
      listCodes: data.listCodes,
      isQrCode: data.isQrCode,
      typeCode: data.typeCode,
      placeName: data.placeName,
      state: data.state,
      date: data.date,
      content: data.content,
      status: data.status,
      categories: data.categories,
      updatedAt: now(),
      updatedBy: this.db.currentUser.id
    };
    if (/*data.image !== undefined && */data.image === null) {
      post.image = null;
    }
    return new Promise((resolve, reject) => {
      this.db.setDocument('promotions', id, post).then(() => {
        this.uploadImage(id, data.image as File).then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  private deleteImage(imagePath: string) {
    return new Promise((resolve, reject) => {
      // console.log(imagePath);
      if (imagePath) {
        this.storage.delete(imagePath).toPromise().then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  async delete(id: string, data: { imagePath: string, lang: string }) {
    if (data.imagePath) {
      const posts: Promotions[] = await this.getWhere('image', '==', data.imagePath).pipe(take(1)).toPromise();
      if (posts.length > 1) {
        data.imagePath = null; // do not delete image if used by more than 1 post
      }
    }
    return new Promise((resolve, reject) => {
     
        this.db.deleteDocument('promotions', id).then(() => {
          this.deleteImage(data.imagePath).then(() => {
            resolve();
          }).catch((error: Error) => {
            reject(error);
          });
        }).catch((error: Error) => {
          reject(error);
        });
      })
    
  }

  setStatus(id: string, status: PostStatus) {
    return this.db.setDocument('promotions', id, { status: status });
  }

  isSlugDuplicated(slug: string, lang: string, id?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getWhereFn(ref => ref.where('slug', '==', slug).where('lang', '==', lang)).pipe(take(1)).toPromise().then((posts: Promotions[]) => {
        //console.log(posts, posts[0]['id']);
        resolve(posts && posts.length && (!id || (posts[0]['id'] as any) !== id));
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  countAll() {
    return this.db.getDocumentsCount('promotions');
  }

  countAllSessions() {
    return this.db.getDocumentsCount('sessions');
  }

  countWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('promotions', queryFn);
  }

  countSessionsWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('sessions', queryFn);
  }

  countWhere(field: any, operator: firebase.firestore.WhereFilterOp, value: string) {
    return this.countWhereFn(ref => ref.where(field, operator, value));
  }

  generateOrderNumber() {
    var minm = 1000;
    var maxm = 9999;
    const orderNumber1 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber2 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber3 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber4 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber = (orderNumber1 + '-' + orderNumber2 + '-' + orderNumber3 + '-' + orderNumber4)
    return orderNumber
   }

}
