import { Observable } from 'rxjs';
import { DocumentTranslation } from './document-translation';

export interface Carte {
  id?: string;
  lang?: string;
  amount?: number;
  amount_paid?: number;
  created?:number;
  date_created?:number;
  lot?: string; // timestamp
  name?:string;
  number?: string;
  place?: string;
  price?: number;
  register?: boolean;
  status?: boolean;
  timeSession?: number;
  type?:string
  cardType?:string
  groupMin?: number;
  use?: number;
  multi?: boolean;

}

export enum PostStatus {
  Draft = 'draft',
  Published = 'published',
  Trash = 'trash'
}

export interface PostTranslation extends DocumentTranslation { }
