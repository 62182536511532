declare var Chart: any;

Chart.Legend.prototype.afterFit = function() {
  this.height = this.height + 25;
};

export function initPieChart(selector: string, data: any[], labels: any[], rewardPaymentsStats) {
  let canvas = document.querySelector(selector);

   var backgroundColor = [
      "rgba(0,123,255,0.9)",
      "rgba(0,143,255,0.5)",
      "rgba(0,183,255,0.5)"
    ];
  
  const chart = new Chart(canvas, {
    type: "pie",
    data: {
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColor
        }
      ],
      labels: labels
    },
    options: {
      title: {
        display: false,
        text: rewardPaymentsStats + " derniers jours"
    },
      responsive: true,
      maintainAspectRatio: false,
      legend: { position: "bottom", labels: { padding: 15, boxWidth: 10 } },
      cutoutPercentage: 0,
      tooltips: { custom: !1, mode: "index", position: "nearest" }
    }
  });

  return chart;
}



export function initBarChartTarif(selector: string, data: any[], labels: any[], rewardDaysStats:number) {
  let canvas = document.querySelector(selector);

    
  const backgroundColor = [
    "rgba(45,127,211,0.9)",
    "rgba(45,127,211,0.9)",
    "rgba(45,127,211,0.8)"
  ];
  
    const hoverBorderColor = "#ffffff"

  var dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
 };
  
  const chart = new Chart(canvas, {
    type: "line",
    data: {
      datasets: [
        {
          hoverBorderColor: hoverBorderColor,
          data: data,
          borderColor: 'rgba(54, 162, 235, 1.0)',
          backgroundColor:'rgba(240,255,255)',
          fill:false,
        },
        
      ],
      labels: labels,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
          display: false,
          text: rewardDaysStats + " derniers jours"
      },
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            }
        }],
    },
      legend: {display:false, inverse:true,},
      tooltips: { custom: !1, mode: "index", position: "nearest" },
      hover: {
          mode: 'nearest',
          intersect: true
      },
  }
  });

  return chart;
}





export function initBarChartMulti(selector: string, data: any[], labels: any[], data22bis:any[], rewardDaysStats:string) {
  let canvas = document.querySelector(selector);

  const backgroundColor = [
    "rgba(45,127,211,0.9)",
    "rgba(45,127,211,0.9)",
    "rgba(45,127,211,0.8)"
  ];
  
    const hoverBorderColor = "#ffffff"

  var dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
 };
  
  const chart = new Chart(canvas, {
    type: rewardDaysStats,
    data: {
      datasets: [
        {
          hoverBorderColor: hoverBorderColor,
          data: data22bis,
          categoryPercentage:0.1,
          borderColor: 'rgba(170, 0, 191, 0.6)',
          backgroundColor:'rgba(170, 0, 191, 0.6)',
          fill: false,
          label: 'Nbre. de produits vendus',
          pointRadius: 5,
        }, 
        /*{
          hoverBorderColor: hoverBorderColor,
          data: data,
          categoryPercentage:0.1,
          borderColor: 'rgba(54, 162, 235, 0.6)',
          backgroundColor:'rgba(54, 162, 235, 0.6)',
          fill: false, 
          label: 'Nbre. de commandes',
        }, */

      ],
      labels: labels,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
          display: false,
          text: rewardDaysStats + " derniers jours"
      },
      scales: {
        xAxes: [{ stacked:false, barPercentage: 0.8}],
        yAxes: [{
          stacked:false,
          minBarLength: 10,
          /*ticks: {
            min: 0,
            max: 100,
            stepSize: 20,
            callback: function (value) {
              return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
              },
          },
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
          },
          */

           /* ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            }*/

        }],
    },
      legend: {display:true},
      tooltips: { custom: !1, mode: "index", position: "nearest",   
      
      /*callbacks: {
        title: function(tooltipItems, data) {
            return data.labels[tooltipItems[0].index] +' H';
        }
    }*/ 
  },
      hover: {
          mode: 'nearest',
          intersect: true
      },
  }
  });

  return chart;
}






export function initBarChartSimple(selector: string, data: any[], labels: any[], rewardDaysStats:string) {
  let canvas = document.querySelector(selector);

  const backgroundColor = [
    "rgba(45,127,211,0.9)",
    "rgba(45,127,211,0.9)",
    "rgba(45,127,211,0.8)"
  ];
  
    const hoverBorderColor = "#ffffff"

  var dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
 };
  
  const chart = new Chart(canvas, {
    type: rewardDaysStats,
    data: {
      datasets: [
        {
          hoverBorderColor: hoverBorderColor,
          data: data,
          categoryPercentage:0.1,
          borderColor: 'rgba(23, 198, 113, 0.6)',
          backgroundColor:'rgba(23, 198, 113, 0.6)',
          fill: false, 
          label: 'Total des ventes (en euro)',
        }, 

      ],
      labels: labels,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
          display: false,
          text: rewardDaysStats + " derniers jours"
      },
      scales: {
        xAxes: [{ stacked:false, barPercentage: 0.8}],
        yAxes: [{
          stacked:false,
          minBarLength: 10,
          /*ticks: {
            min: 0,
            max: 100,
            stepSize: 20,
            callback: function (value) {
              return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
              },
          },
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
          },
          */

           /* ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            }*/

        }],
    },
      legend: {display:true},
      tooltips: { custom: !1, mode: "index", position: "nearest",   
      
      /*callbacks: {
        title: function(tooltipItems, data) {
            return data.labels[tooltipItems[0].index];
        }
    }*/
  },
      hover: {
          mode: 'nearest',
          intersect: true
      },
  }
  });

  return chart;
}




export function initBarChart(selector: string, data: any[], labels: any[], rewardPlacesStats) {
  let canvas = document.querySelector(selector);
  
  const chart = new Chart(canvas, {
    type: "bar",
    data: {
      datasets: [
        {
          data: data,
          label: 'Nbre. de ventes',
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
        }
      ],
      labels: labels,
    },
    options: {
      title: {
        display: false,
        text: rewardPlacesStats + " derniers jours"
    },
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }

                },
            }
        }],
    },
    responsive: true,
    maintainAspectRatio: false,
      legend: {display:false},
      tooltips: { custom: !1, mode: "index", position: "nearest" },
    }
  });

  return chart;
}







export function initChartTime(selector: string, data: any[], labels: any[], rewardPlacesStats) {
  let canvas = document.querySelector(selector);
  
  const chart = new Chart(canvas, {
    data: {
      datasets: [{
        label: 'Dataset with string point data',
        backgroundColor: 'rgba(54, 162, 235, 0.8)',
        borderColor: 'rgba(84, 162, 235, 0.8)',
        fill: false,
        data: [{
          x: 0,
          y: Math.random()
        }, {
          x: 2,
          y: Math.random()
        }, {
          x: 4,
          y: Math.random()
        }, {
          x: 6,
          y: Math.random()
        }],
      }, {
        label: 'Dataset with date object point data',
        backgroundColor: 'rgba(84, 162, 235, 0.8)',
        borderColor: 'rgba(84, 162, 235, 0.8)',
        fill: false,
        data: [{
          x: 0,
          y: Math.random()
        }, {
          x: 1,
          y: Math.random()
        }, {
          x: 4,
          y: Math.random()
        }, {
          x: 6,
          y: Math.random()
        }]
      }]
    },
    type: 'line',

  options: {
    spanGaps: 1000 * 60 * 60 * 24 * 2, // 2 days
    responsive: true,
    interaction: {
      mode: 'nearest',
    },
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Time - spanGaps: 172800000 (2 days in ms)'
      },
    },
    scales: {
      x: {
        type: 'time',
        display: true,
        title: {
          display: true,
          text: 'Date'
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          major: {
            enabled: true
          },
          // color: function(context) {
          //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
          // },
          font: function(context) {
            if (context.tick && context.tick.major) {
              return {
                weight: 'bold',
              };
            }
          }
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'value'
        }
      }
    }
  }
  })
  
  return chart;
}





export function initLineChart(selector: string, data: any[], labels: any[], data2: any[], labels2: any[], data3: any[], labels3: any[], backgroundColor: string[] = [], hoverBorderColor: string = "#ffffff") {
  let canvas = document.querySelector(selector);

    backgroundColor = [
      "rgba(62,169,89,0.9)",
      "rgba(62,169,89,0.5)",
      "rgba(62,169,89,0.3)"
    ];
  
  const chart = new Chart(canvas, {
    type: "line",
    data: {
      datasets: [
        {
          label: 'Tickets vendus',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(45,127,211,0.4)',
            borderColor: 'rgba(45,127,211,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(45,127,211,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(45,127,211,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: data,
            spanGaps: false,
        },{
          label: 'Tickets scannés',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(62,169,89,0.4)',
          borderColor: 'rgba(62,169,89,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(62,169,89,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(62,169,89,1)',
          pointHoverBorderColor: 'rgba(139,211,45,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: data2,
          spanGaps: false,
        },
        /*
        {
          label: 'Recette',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(154,45,211,0.4)',
          borderColor: 'rgba(154,45,211,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(154,45,211,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(154,45,211,1)',
          pointHoverBorderColor: 'rgba(139,211,45,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: data3,
          spanGaps: false,
        }*/
      ],
      labels: labels,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      barThickness: 1,
      name: ['Current data','Vs last week/month/year','Change'],
      legend: { position: "bottom", labels: { padding: 25, boxWidth: 20 } },
      cutoutPercentage: 0,
      tooltips: { custom: !1, mode: "index", position: "nearest" },
  scales: {
      yAxes: [{
          display: true,
          ticks: {
              beginAtZero: true,
              min: 0
          }
      }]
  },
  title: {
      display: true,
      text: name
  },
  hover: {
      mode: 'nearest',
      intersect: true
  },
    }
  });

  return chart;
}









export function initLineChartAllDAtePlace(selector: string, data: any[], labels: any[], backgroundColor: string[] = [], hoverBorderColor: string = "#ffffff") {
  let canvas = document.querySelector(selector);

    backgroundColor = [
      "rgba(62,169,89,0.9)",
      "rgba(62,169,89,0.5)",
      "rgba(62,169,89,0.3)"
    ];
  
  const chart = new Chart(canvas, {
    type: "line",
    data: {
      datasets: [
        {
          label: 'Tickets vendus',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(45,127,211,0.4)',
            borderColor: 'rgba(45,127,211,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(45,127,211,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(45,127,211,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: data,
            spanGaps: false,
            
        }
      ],
      labels: labels,
    },
    options: {

      barThickness: 1,
      name: ['Current data','Vs last week/month/year','Change'],
      legend: { position: "bottom", labels: { padding: 25, boxWidth: 20 } },
      cutoutPercentage: 0,
      tooltips: { custom: !1, mode: "index", position: "nearest" },
       responsive: true,
      maintainAspectRatio: false,
  scales: {
      yAxes: [{
          display: true,
          ticks: {
              beginAtZero: true,
              min: 0
          }
      }]
  },
  title: {
      display: true,
      text: name
  },
  hover: {
      mode: 'nearest',
      intersect: true
  },
    }
  });

  return chart;
}

