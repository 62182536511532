// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //PROD
  
  firebase: {
    apiKey: "AIzaSyCJV9IT95bT4HkV9hS6FI9EWrTWu4o0Sl4",
    authDomain: "glisspass.firebaseapp.com",
    databaseURL: "https://glisspass-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "glisspass",
    storageBucket: "glisspass.appspot.com",

    appId: "1:1077471750223:web:f26be7daead4e88bfd223a",
    measurementId: "G-D5H19MGKZE"
  }
/*
firebase: {
  apiKey: "AIzaSyDWuQCeqY2q-3CiVpv6-Me44nCkzDGH0JE",
  authDomain: "glisspassindev-1bf32.firebaseapp.com",
  projectId: "glisspassindev-1bf32",
  storageBucket: "glisspassindev-1bf32.appspot.com",
  messagingSenderId: "961487131738",
  appId: "1:961487131738:web:828e306cca626fbfadf463",
  measurementId: "G-D5H19MGKZE",
}
*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
