
export const fr = {

  // Login page
  "SignInToYourAccount": "Connexion Staff",
  "EmailAddress": "Adresse mail",
  "EnterEmail": "Entrez votre e-mail",
  "Password": "Mot de passe",
  "RememberMe": "Se souvenir de moi",
  "SignIn": "SE CONNECTER",
  "ForgotYourPassword": "Mot de passe oublié?",
  // Register page
  "WelcomeToFireAdmin": "Bienvenue dans FireAdmin",
  "RepeatPassword": "Répéter le mot de passe",
  "CreateAccount": "Créer un compte",
  // Sidebar
  "Dashboard": "Rapport & Statistiques",
  "Pages": "Pages",
  "Customers": "Installations GlissPASS",
  "List": "Liste",
  "Add": "Ajouter",
  "Posts": "Etablissements",
  "Notifications": "Notifications",
  "Partners": "Partenaires",
  "Promotions": "Promotions",
  "Sessions": "Commandes",
  "Cartes": "Tickets",
  "Categories": "Catégories",
  "CategoriesTarifs":"Types de tarif",
  "Translations": "Traductions",
  "Comments": "Commentaires",
  "Menus": "Menus",
  "Media": "Médias",
  "Users": "Employés & invités",
  // Navbar
  "AddPost": "Ajouter un établissement",
  "Profile": "Profil & activité",
  "Settings": "Réglages",
  "Logout": "Se déconnecter",
  //Resume
  "OrdersByPlace": "Ventes par établissement",
  "OrdersByDate": "Ventes totales par jour",
  "OrdersByPaymentType": "Types de paiement utilisés",
  "OrdersByMyPlaces": "Tous mes établissements",
  "OrdersByTime": "Ventes par heure",
  //Customers
  "ListCustomers":"Liste des clients",
  // Dashboard
  "PostsByStatus": "Status des établissements",
  "ChartsSold": "Ventes de tickets",
  "ChartsUsed": "Visiteurs",
  // Pages: Add
  "AddNewPage": "Ajouter une nouvelle page",
  "PageTitle": "Titre de la page",
  "PageSlug": "Slug",
  "PageLanguage": "Langue",
  "PageBlock": "Bloc",
  "PageBlocks": "Blocs",
  "AddPage": "Ajouter la page",
  "AddBlock": "Ajouter un bloc",
  "BlockName": "Nom",
  "BlockType": "Type",
  "BlockContent": "Contenu",
  "RemoveBlock": "Retirer",
  "CollapseBlock": "Ranger",
  "PageAdded": "Page ajoutée avec succès!",
  "PageSlugAlreadyExists": "Le slug de la page existe déjà! Veuillez en choisir un autre.",
  // Pages: Block types
  "Text": "Text",
  "HTML": "HTML",
  "JSON": "JSON",
  // Pages: Edit
  "EditPage": "Modifier la page",
  "PageSaved": "Page enregistrée avec succès!",
  // Pages: Translate
  "TranslatePage": "Traduire la page",
  // Pages: List
  "PageCreatedAt": "Date de création",
  "PageUpdatedAt": "Date de la dernière modification",
  "PageAuthor": "Auteur",
  "DeletePage": "Supprimer la page",
  "ConfirmDeletePage": "Veuillez confirmer la suppression de la page <b>${title}</b> ?",
  "PageDeleted": "Page <b>${title}</b> supprimée!",

  // Sessions
  "SessionNumberPass": "N° de commande",
  "SessionName": "Commande",
  "SessiononIce": "en attente de récupération",
  "SessionType": "Status",
  "SessionItems": "Produits",
  "SessionLocation": "Lieu",
  "SessionsPersonnes": "articles(s)",
  "SessionsOptions": "Options",
  "SessionsByStatus": "Commandes par établissement",
  "SessionOrderByRink": "Trier par établissement",
  "SessionSince": "Date",
  "SessionPlace": "Établissement",
  "SessionDescription": "Description",
  "SessionPrice": "Total",
  "SessionCreateAt": "Créé le",
  "SessionRegister": "Relié",
  "SessionUpdate": "De nouveaux éléments sont disponibles.",
  "SessionUpdateButton": "Actualiser",
  "revenus":"Recette",
  "EditSession":"Status de la commande",
  "SessionSaved": "Commande mise à jour avec succès",
  "PASS":"GlissPASS (PASS enregistré)",
  "card":"GlissPASS (Boutique Application)",
  "caisse":"Caisse (espèce)",
  "expert":"Caisse (expert)",
  "productsSell":"Produits vendus",
  // Cartes
  "cardSold":"tickets vendus",
  "cardValid":"tickets valides",
  "appInstall":"GlissPASS installés",

  // Posts: Add
  "AddNewPost": "Ajouter un établissement",
  "PostTitle": "Nom de l'établissement",
  "PostContent": "Description de l'établissement",
  "Actions": "Actions",
  "PostLanguage": "Langue",
  "PostStatus": "Statut",
  "PostDate": "Date",
  "PostImage": "Bannière / Image",
  "PostSlug": "Slug",
  "PostEmail": "Email",
  "PostPhone": "Téléphone",
  "SaveDraft": "Enregistrer le brouillon",
  "Publish": "Publier",
  "MoveToTrash": "Mettre à la corbeille",
  "PostAdded": "Etablisssment ajoutée avec succès!",
  "PostSlugAlreadyExists": "Le slug (réécriture d'URL) de l'établissement existe déjà ! Veuillez en choisir un autre.",
  "PostContact": "Contact",
  "PostBank": "Compte bancaire de l\'établissement",

    // Notifications: Add
    "AddNewNotification": "Envoyer une notification",
    "NotificationTitle": "Titre de la notification",
    "NotificationRedirection": "URL de redirection",
    "NotificationContent": "Message de la notification",
    "NotificationLanguage": "Langue",
    "EditNotfication": "Notification envoyée",
    "NotificationStatus": "Statut",
    "SendNotification": "Envoyer une notification",
    "NotificationDate": "Date",
    "NotificationImage": "Image",
    "NotificationSlug": "Slug",
    "NotificationAdded": "Notification envoyée avec succès!",
    "NotificationSlugAlreadyExists": "Le titre de la notification existe déjà ! Veuillez en choisir un autre.",
    "NotificationContact": "Contact",

     // Partners: Add
     "AddNewPartners": "Ajouter une annonce",
     "PartnersTitle": "Titre de l'annonce",
     "PartnersRedirection": "URL de redirection",
     "PartnersContent": "Contenu de l'annonce",
     "PartnersLanguage": "Langue",
     "EditPartners": "Annonce modifiée",
     "PartnersStatus": "Statut",
     "SendPartners": "Modifier l'annonce",
     "PartnersDate": "Date",
     "PartnersImage": "Image",
     "PartnersSlug": "Slug",
     "PartnersAdded": "Annonce ajoutée avec succès!",
     "PartnersSlugAlreadyExists": "Le titre de l'annonce' existe déjà ! Veuillez en choisir un autre.",
     "PartnersContact": "Contact",

     
     // Promotions: Add
     "AddNewPromotions": "Créer un code promo / Invitation",
     "PromotionsTitle": "Nom du produit offert",
     "PromotionsRedirection": "URL de redirection",
     "PromotionsContent": "Description",
     "PromotionsLanguage": "Langue",
     "EditPromotions": "Promotion modifiée",
     "EditInvitations": "Invitation modifiée",
     "PromotionsStatus": "Statut",
     "SendPromotions": "Modifier l'annonce",
     "PromotionsDate": "Date",
     "PromotionsImage": "Image",
     "PromotionsSlug": "Slug",
     "PromotionsAdded": "Promo/Invitation ajoutée avec succès!",
     "PromotionsSlugAlreadyExists": "Le titre de l'annonce' existe déjà ! Veuillez en choisir un autre.",
     "PromotionsContact": "Contact",
     "true":"Déjà utilisé",
     "false":"Disponible",
     "productItem":"Produit",
     "ConfirmDeletePromotion": "Veuillez confirmer la suppression de <b>${title}</b> ?",
     "PromotionsCountCode":"Nbre. de codes",

  // Posts: Status
  "Draft": "Ouvre bientôt",
  "Published": "Ouvert",
  "Trash": "Fermeture",
   // Session: Status
   "Succeeded": "en attente de récupération",
   "Terminated": "Terminée (récupérée)",
   "Requires_payment_method": "Incomplet",
   "Creating": "création...",
  // Posts: List
  "PostAll":"Tous les établissements",
  "PostCreatedAt": "Date de création",
  "PostCreatedAtShort": "créé le",
  "PostUpdatedAt": "Date de la dernière modification",
  "PostUpdatedAtShort": "modifié le",
  "PostAuthor": "Auteur",
  "Translate": "Traduire",
  "DeletePost": "Supprimer l'établissement",
  "ConfirmDeletePost": "Veuillez confirmer la suppression de l'établissement <b>${title}</b> ?",
  "PostDeleted": "Etablissement <b>${title}</b> supprimé!",
  // Posts: Edit
  "EditPost": "Modifier l'établissement",
  "StatsPost": "Statistiques de l'établissement",
  "PostSaved": "Etablissement enregistrée avec succès!",
  // Posts: Translate
  "TranslatePost": "Traduire cet établissement",
  // Languages
  "All": "Tout",
  "English": "Anglais",
  "French": "Français",
  "Arabic": "Arabe",
  // Settings
  "General": "Général",
  "Internationalization": "Internationalisation",
  "Language": "Langue",
  "siteName": "Nom de la solution",
  "SidebarStyle": "Style de barre latérale",
  "Expanded": "Étendu",
  "Collapsed": "Rangé",
  "HeaderBar": "Barre d'en-tête",
  "SaveChanges": "Sauvegarder les modifications",
  "SettingsSaved" : "Paramètres enregistrés avec succès!",
  "SupportedLanguages" : "Langues prises en charge",
  "Key": "Clé",
  "Remove": "Supprimer",
  "Cancel": "Annuler",
  "logoutOK":"Vous êtes déconnecté de votre compte utilisateur.",
  // Categories
  "NewCategory": "Nouvelle catégorie",
  "CategoryLabel": "Libellé",
  "CategorySlug": "Slug",
  "CategoryLanguage": "Langue",
  "AddCategory": "Ajouter une catégorie",
  "CategoryAdded": "Catégorie ajoutée avec succès!",
  "DeleteCategory": "Supprimer la catégorie",
  "ConfirmDeleteCategory": "Veuillez confirmer la suppression de la catégorie <b>${label}</b> ?",
  "CategoryDeleted": "Catégorie <b>${label}</b> supprimée!",
  "EditCategory": "Modifier la catégorie",
  "CategorySaved": "Catégorie <b>${label}</b> enregistrée!",
    // Categories Tarifs
    "NewCategoryTarif": "Nouveau type de tarif",
    "CategoryLabelTarif": "Libellé",
    "CategorySlugTarif": "Slug",
    "CategoryLanguageTarif": "Langue",
    "AddCategoryTarif": "Ajouter un type de tarif",
    "CategoryAddedTarif": "Type de tarif ajouté avec succès!",
    "DeleteCategoryTarif": "Supprimer le type de tarif",
    "ConfirmDeleteCategoryTarif": "Veuillez confirmer la suppression du type de tarif <b>${label}</b> ?",
    "CategoryDeletedTarif": "Type de tarif <b>${label}</b> supprimé!",
    "EditCategoryTarif": "Modifier le type de tarif",
    "CategorySavedTarif": "Type de tarif <b>${label}</b> enregistré!",
  // Lists
  "Edit": "Modifier",
  "Delete": "Supprimer",
  "Save": "Enregistrer",
  "Send": "Envoyer",
  "Create": "Créer",
  "Resend": "Renvoyer",
  // Users: Add
  "AddNewUser": "Ajouter un nouvel utilisateur",
  "AddUser": "Ajouter le nouvel utilisateur",
  "NewUser": "Nouvel utilisateur",
  "FirstName": "Nom",
  "LastName": "Prénom",
  "Email": "Email",
  "PlaceEmployee": "Employé de patinoire",
  "BirthDate": "Date de mise en ligne",
  "Role": "Rôle",
  "Bio": "Description du compte",
  "BioPlaceholder": "Ajoutez une note explicative ou une description sur l'utilisateur (facultatif)",
  "ProfilePicture": "Photo de profil",
  "UploadImage": "Télécharger une image",
  "UserAdded": "Utilisateur ajouté avec succès!",
  // Users: Edit
  "EditUser": "Modifier l'utilisateur",
  "ListUsers":"Liste des utilisateurs",
  "UserDetails": "Détails de l'utilisateur",
  "UpdateUser": "Mettre à jour l'utilisateur",
  "UserUpdated": "Utilisateur mis à jour avec succès!",
  "UserBankAccountRemoved": "Le compte Stripe Pro et les comptes bancaires associés sont supprimés.",
  "UserBankAccountOK": "Opération réussie depuis le prestataire de paiement Stripe.",
  "UserBankAccountCancel": "Opération refusée par le prestataire de paiement Stripe.",
  "PostUsersList":"Utilisateurs de l'établissement",
  // Users: Profile
  "UserProfile": "Profil de l'utilisateur",
  "LatestPosts": "Liste de mes établissements",
  "LatestPostsStats": "Statistiques par établissement",
  "LatestSessions": "Trafic en temps réel",
  "RapportSessions": "Rapport",
  "FullList": "Liste complète",
  "RapportFullList": "Voir le rapport détaillé",
  "ExportTo": "Exporter le rapport",
  // Charts: Status
  "ChartsDraft": "Fermé",
  "ChartsPublished": "Ouvert",
  "ChartsTrash": "Corbeille",
  // Users: List
  "UserName": "Nom d'utilisateur",
  "CreatedAt": "Date de création",
  "UpdatedAt": "Date de la dernière modification",
  "CreatedBy": "Créé par",
  "DeleteUser": "Supprimer l'utilisateur",
  "ConfirmDeleteUser": "Veuillez confirmer la suppression de l'utilisateur <b>${name}</b> ?",
  "UserDeleted": "Utilisateur <b>${name}</b> supprimé!",
  "UserChoicePlace": "Établissements attribués à l'utilisateur",
  "UserBank": "Compte bancaire par défaut",
  // Users: Roles
  "Administrator": "Administrateur - Direction d\'établissement",
  "admin": "Administrateur/Direction",
  "Editor": "Éditeur",
  "editor": "Éditeur",
  "Author": "Auteur",
  "author": "Auteur",
  "Contributor": "Contributeur",
  "contributor": "Contributeur",
  "Employee": "Employé",
  "employee": "Employé",
  "Regisseur": "Régisseur",
  "regisseur": "Régisseur",
  "EmployeeCaisse": "Employé de caisse",
  "employee_caisse": "Employé de caisse",
  "Guest": "Invité - partenaire",
  "Vendor":"Vendeur - Propriétaire d\'établissement",
  "vendor":"Vendeur/Propriétaire",
  "guest": "Invité - partenaire",
  // Translations
  "NewTranslation": "Nouvelle traduction",
  "TranslationKey": "Clé",
  "TranslationValue": "Valeur",
  "TranslationLanguage": "Langue",
  "AddTranslation": "Ajouter une traduction",
  "TranslationAdded": "Traduction ajoutée avec succès!",
  "DeleteTranslation": "Supprimer la traduction",
  "ConfirmDeleteTranslation": "Veuillez confirmer la suppression de la traduction <b>${key}</b> ?",
  "TranslationDeleted": "Traduction <b>${key}</b> supprimée!",
  "EditTranslation": "Modifier la traduction",
  "TranslationSaved": "Traduction <b>${key}</b> enregistrée!",
  "TranslationKeyAlreadyExists": "La clé de traduction <b>${key}</b> existe déjà pour la langue <b>${lang}</b>.",
  "TranslationsTooltip": "Les traductions peuvent être utilisées avec la bibiliothèque <a href=\"http://www.ngx-translate.com/\" target=\"_blank\">ngx-translate</a>, pour tout texte répétitif (en-tête, pied de page ou fil d'Ariane) à traduire.",
  // Error messages
  "GuestsAreNotAllowedToPerformChanges": "Les invités ne sont pas autorisés à effectuer des modifications.",

  //tmp place name
  "SI0N1LX4WyTcs1F8pzf5VfnDSGA2":'Luge Belfort'
};
