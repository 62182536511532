import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Carte, PostTranslation, PostStatus } from '../../models/collections/carte.model';
import { now, guid, isFile } from '../../helpers/functions.helper';
import { StorageService } from '../storage.service';
import { map, take, mergeMap } from 'rxjs/operators';
import { of, merge, Observable } from 'rxjs';
import { getEmptyImage, getLoadingImage } from '../../helpers/assets.helper';
import { SettingsService } from '../settings.service';
import { Language } from '../../models/language.model';
import { UsersService } from './users.service';
import { QueryFn } from '@angular/fire/firestore';
import { DocumentTranslationsService } from './abstract/document-translations.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class CartesService extends DocumentTranslationsService {

  private allStatus: object = {};
  private statusColors: object = {
    draft: 'warning',
    published: 'success',
    trash: 'danger'
  };
  private imagesCache: object = {};

  constructor(
    protected db: DatabaseService,
    private storage: StorageService,
    private settings: SettingsService,
    private users: UsersService
  ) {
    super(db, 'postTranslations');
    Object.keys(PostStatus).forEach((key: string) => {
      this.allStatus[PostStatus[key]] = key;
    });
  }

  getAllStatus() {
    return this.allStatus;
  }

  getAllStatusWithColors() {
    return { labels: this.allStatus, colors: this.statusColors };
  }

  getStatus(statusKey: string) {
    return this.allStatus[statusKey];
  }

  get(id: string) {
    return this.db.getDocument('cartes', id).pipe(mergeMap(async (post: Carte) => {
      post.id = id;
      return post;
    }));
  }

  add(data: Carte, translationId?: string) {
    const post: Carte = {
      amount: data.amount,
      amount_paid: data.amount_paid,
      date_created: data.date_created,
      created: data.created,
      lot: data.lot,
      name: data.name,
      number: data.number,
      place: data.place,
      price: data.price,
      register: data.register,
      timeSession: data.timeSession,
      status: data.status,
      type: data.type
    };
  }




  private pipePosts(postsObservable: Observable<Carte[]>) {
    return postsObservable.pipe(mergeMap(async (posts: Carte[]) => {
      //const activeSupportedLanguages = this.settings.getActiveSupportedLanguages().map((lang: Language) => lang.key);
      //posts.forEach((post: Post) => { // forEach loop doesn't seems to work well with async/await
      for (let post of posts) {
        // console.log(post);
        //post.translations = await this.getTranslations(post.translationId).pipe(take(1)).toPromise();
        // console.log(post.translations);
        //const postLanguages = Object.keys(post.translations);
        //post.image = {
        //  path: post.image,
         // url: post.image ? merge(of(getLoadingImage()), this.getImageUrl(post.image as string)) : of(getEmptyImage())
        //};
        //post.author = post.createdBy ? this.users.getFullName(post.createdBy) : of(null);
        //post.isTranslatable = !activeSupportedLanguages.every((lang: string) => postLanguages.includes(lang));
      }
      //});
      return posts;
    }));
  }

  getAll() {
    //return this.pipePosts(this.db.getCollection('cartes'));
    return this.db.getDocument('rapports', 'all')
  }

  getAllDate() {
    //return this.pipePosts(this.db.getCollection('cartes'));
    return this.db.getCollection('orders', ref => {
      let query: any = ref;
      // Filter by author
        query = query.orderBy('created', 'asc');
      //query = query.orderBy('createdAt', 'desc'); // requires an index to work
      return query;
    })
  }

  getToday(place:string) {
    //return this.pipePosts(this.db.getCollection('cartes'));
    return this.db.getCollection('rapports')
  }

  getTarifStats(date:number) {

    /*
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    const todayRapport = dd + '-' + mm + '-' + yyyy;*/

    var dateok = date * 1000
    const datePipe = new DatePipe('en-US');
    var todayRapport = datePipe.transform(dateok, 'dd-MM-yyyy');

    return this.db.getDocument('statistiques', todayRapport)
  }

  getTodayPlace(place:string, date:number) {
    //return this.pipePosts(this.db.getCollection('cartes'));
    //console.log('date 1', date)
    var dateok = date * 1000
    const datePipe = new DatePipe('en-US');
    var todayRapport = datePipe.transform(dateok, 'dd-MM-yyyy');

    //console.log('date', todayRapport)

    //var today = new Date();
    //var dd = String(today.getDate()).padStart(2, '0');
    //var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    //var yyyy = today.getFullYear();
    
    //const todayRapport = dd + '-' + mm + '-' + yyyy;
   
    return this.db.getDocumentCollectionDocument('rapports', place, 'date', todayRapport)
  }

  getPlaceAllDate(place:string) {   
    return this.db.getCollection('rapports/' + place + '/date', ref => {
      let query: any = ref;
      // Filter by author
        query = query.orderBy('lastUpdate', 'asc');
      //query = query.orderBy('createdAt', 'desc'); // requires an index to work
      return query;
    })
  }


  getAllStats() {

    return this.db.getCollection('orders', ref => {
      let query: any = ref;
      // Filter by author
        query = query.orderBy('created', 'desc');
      //query = query.orderBy('createdAt', 'desc'); // requires an index to work
      return query;
    })


    return this.db.getCollection('statistiques')
  }


  getWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string, applyPipe: boolean = false) {
    return this.getWhereFn(ref => ref.where(field, operator, value), applyPipe);
  }

  getWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('cartes', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }


  edit(id: string, data: Carte) {
    const post: Carte = {

      amount: data.amount,
      amount_paid: data.amount_paid,
      date_created: data.date_created,
      created: data.created,
      lot: data.lot,
      name: data.name,
      number: data.number,
      place: data.place,
      price: data.price,
      register: data.register,
      timeSession: data.timeSession,
      status: data.status,
      type: data.type

    };

    return new Promise((resolve, reject) => {
      this.db.setDocument('posts', id, post).then(() => {
      }).catch((error: Error) => {
        reject(error);
      });
    });

  }

  private deleteImage(imagePath: string) {
    return new Promise((resolve, reject) => {
      // console.log(imagePath);
      if (imagePath) {
        this.storage.delete(imagePath).toPromise().then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  async delete(id: string, data: { imagePath: string, lang: string, translationId: string, translations: PostTranslation }) {
    if (data.imagePath) {
      const posts: Carte[] = await this.getWhere('image', '==', data.imagePath).pipe(take(1)).toPromise();
      if (posts.length > 1) {
        data.imagePath = null; // do not delete image if used by more than 1 post
      }
    }
    return new Promise((resolve, reject) => {
      this.deleteTranslation(data.translationId, data.lang, data.translations).then(() => { // should be done before deleting document (posts observable will be synced before if not)
        this.db.deleteDocument('sessions', id).then(() => {
          this.deleteImage(data.imagePath).then(() => {
            resolve();
          }).catch((error: Error) => {
            reject(error);
          });
        }).catch((error: Error) => {
          reject(error);
        });
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  setStatus(id: string, status: PostStatus) {
    return this.db.setDocument('sessions', id, { status: status });
  }

  isSlugDuplicated(slug: string, lang: string, id?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getWhereFn(ref => ref.where('slug', '==', slug).where('lang', '==', lang)).pipe(take(1)).toPromise().then((posts: Carte[]) => {
        //console.log(posts, posts[0]['id']);
        resolve(posts && posts.length && (!id || (posts[0]['id'] as any) !== id));
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  countAll() {
    return this.db.getDocumentsCount('rapports');
  }

  countWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('rapports', queryFn);
  }

  countWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string) {
    return this.countWhereFn(ref => ref.where(field, operator, value));
  }

}
