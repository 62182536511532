import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { User, UserRole } from '../models/collections/user.model';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class UserGuard implements CanActivate {

  constructor(private currentUser: CurrentUserService, private navigation: NavigationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const user: User = await this.currentUser.get();
      //console.log(user);
      //console.log('vestyyyyy', route)
     //console.log('route', route)
      const machin = user['places'].indexOf(route.params['id']) > -1

      if (user.role === UserRole.Administrator) {
        resolve(true);
      } else {
        //console.log(route);
        // A non admin user can only consult its own profile
        if (route.url[0].path === 'profile' && route.params['id'] === user.id) {
          resolve(true);
        }

        else if (route.url[0].path === 'sessions' && user.role === UserRole.Vendor) {
          resolve(true);
        }

        // After admin, only editors are allowed to modify their own informations
        else if ((user.role === UserRole.Editor || user.role === UserRole.Vendor) && route.url[0].path === 'edit') {
          if((user['places'].indexOf(route.params['id']) > -1) === true) {
            resolve(true);
          } else {
          resolve(false);
         }
        }

        

        // After admin, only editors are allowed to modify their own informations
        //else if (user.role === UserRole.Vendor && route.url[0].path === 'posts') {

          else if(route['_urlSegment'].segments[2].path === 'stats') {
            if((user['places'].indexOf(route.params['id']) > -1) === true) {
              resolve(true);
            } else {
            resolve(false);
           }
          //} else {
          //  resolve(false);
          //}
          
        }

        // Redirect to dashboard on any other attempts
        else {
          this.navigation.redirectTo('dashboard');
          resolve(false);
          console.log('access denied')
        }
      }
    });
  }

}
