import { Component, OnInit, OnDestroy } from '@angular/core';
import { initTextEditor } from '../../../helpers/posts.helper';
import { I18nService } from '../../../services/i18n.service';
import { slugify } from '../../../helpers/functions.helper';
import { PostsService } from '../../../services/collections/posts.service';
import { Post } from '../../../models/collections/post.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { SessionsService } from '../../../services/collections/sessions.service';
import { NavigationService } from '../../../services/navigation.service';
import { Session, SessionStatus } from '../../../models/collections/sessions.model';
import { getEmptyImage } from '../../../helpers/assets.helper';
import { ActivatedRoute } from '@angular/router';
import { firestore } from 'firebase';

import {} from 'googlemaps'


@Component({
  selector: 'fa-posts-edit',
  templateUrl: './posts-edit.component.html',
  styleUrls: ['./posts-edit.component.css']
})
export class SessionEditComponent implements OnInit, OnDestroy {

  private id: string;
  orderNumber: string;
  cart:any = []
  created:number
  lastmodified:number
  paymentIntent:any = {}
  place:string
  status:SessionStatus
  total:number
  uid:string
  initStatus:SessionStatus
  orderPlace:any = []
  checkedCategories: string[] = [];
  categoriesObservable: Observable<Post[]>;
  newCategory: string;
  isSubmitButtonsDisabled: boolean = false;
  allStatus: object|any = {};
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();
  filterPlace:any
  listItemsPlace:any[] = []

  europeanCards: any = [
    "AT",
    "BE",
    "BG",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GI",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK"
]

  constructor(
    private i18n: I18nService,
    private categories: PostsService,
    private alert: AlertService,
    private sessions: SessionsService,
    public navigation: NavigationService,
    private route: ActivatedRoute
  ) { }

  groupByKey(array, key) {
    return array
      .reduce((hash, obj) => {
        if(obj[key] === undefined) return hash; 
        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
      }, {})
  }


  ngOnInit() {
    this.allStatus = this.sessions.getAllStatus();
    this.isSubmitButtonsDisabled = true;
    this.subscription.add(
      this.route.params.subscribe((params: { id: string }) => {
        this.filterPlace = params.id
        this.sessions.get(params.id).pipe(take(1)).toPromise().then((session: Session) => {
          // console.log(post);
          if (session) {
            this.id = session.id;
            this.orderNumber = session.orderNumber;
            this.cart = session.cart
            this.created = session.created
            this.lastmodified = session.lastmodified
            this.paymentIntent = session.paymentIntent
            this.place = session.place
            this.status = session.status
            this.initStatus = session.status
            this.total = session.total
            this.uid = session.uid
            this.setCategoriesObservable();
            this.routeParamsChange.next();
            this.isSubmitButtonsDisabled = false;
            const parplace = this.groupByKey(session.cart, 'place')
            this.listItemsPlace = parplace
          } else {
            this.navigation.redirectTo('posts', 'list');
          }
        });
      })
    );
    
  }



  private setCategoriesObservable() {
     this.categories.getWhere(firestore.FieldPath.documentId(), '==', this.place).subscribe((result) => {
       console.log(result)
      this.orderPlace = result[0].title
    })
  }

private getPlaceName(uid) {
     this.categories.getWhere(firestore.FieldPath.documentId(), '==', uid).subscribe((result) => {
       console.log(result)
      this.orderPlace = result[0].title
    })
  }
  

countTotalByPlace(data) {
  let totalByPlace = data.reduce((accumulator, object) => {
    return accumulator + (object.price*100);
    }, 0);
    return totalByPlace
}


getStripeCommission(country:any, total:number) {
 const truc =  this.europeanCards.includes(country)
 if(truc === true && country !== "GB") {
   return Math.round((((total/100)*1.4/100) + 0.25)*100)/100
 } 
 if(country == "GB") {
  return Math.round((((total/100)*2.5/100) + 0.25)*100)/100
 }
 else {
  return Math.round((((total/100)*2.9/100) + 0.25)*100)/100
 }
}


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routeParamsChange.next();
  }


  savePost(event: Event) {
    const target = event.target as any;
    const startLoading = () => {
      target.isLoading = true;
      this.isSubmitButtonsDisabled = true;
    };
    const stopLoading = () => {
      target.isLoading = false;
      this.isSubmitButtonsDisabled = false;
    };
    startLoading();


        // Edit post
        const data: Session = {
         // Edit order
          orderNumber: this.orderNumber,
          cart: this.cart,
          created: this.created,
          lastmodified: this.lastmodified,
          paymentIntent: this.paymentIntent,
          place: this.place,
          status: this.status,
          total: this.total,
          uid: this.uid,

        };

        console.log('nouvelle données', data)
        

        this.sessions.edit(this.id, data).then(() => {
         this.alert.success(this.i18n.get('SessionSaved'), false, 5000, true);
          this.navigation.redirectTo('sessions', 'list');
        }).catch((error: Error) => {
          this.alert.error(error.message);
        }).finally(() => {
          stopLoading();
        });
      

  }

}
