import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { initTextEditor } from '../../../helpers/posts.helper';
import { I18nService } from '../../../services/i18n.service';
import { SettingsService } from '../../../services/settings.service';
import { slugify } from '../../../helpers/functions.helper';
import { Language } from '../../../models/language.model';
import { CategoriesPromotionsService } from '../../../services/collections/categories-promotions.service';
import { Category } from '../../../models/collections/category.model';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { PromotionsService } from '../../../services/collections/promotions.service';
import { NavigationService } from '../../../services/navigation.service';
import { PostStatus } from '../../../models/collections/promotions.model';
import { getEmptyImage } from '../../../helpers/assets.helper';
import { PostsService } from '../../../services/collections/posts.service';
import { jsPDF } from "jspdf";
import QRCode from 'qrcode'

@Component({
  selector: 'fa-posts-add',
  templateUrl: './promotions-add.component.html',
  styleUrls: ['./promotions-add.component.css']
}) 



export class PromotionsAddComponent implements OnInit, AfterViewInit, OnDestroy {
  
  title:string = "none";
  editor: string = '';
  editor2: string = 'Valable de 12h à 14h les lundi';
  editor3: string = 'mardi, jeudi, et vendredi';
  editor4: string = 'hors vacances scolaires';
  private status: PostStatus;
  language: string;
  languages: Language[];
  slug: string;
  number:any
  place:string = "none";
  name:string = "none";
  placeName:string;
  state:boolean = false;
  date: string;
  private image: File;
  imageSrc: string|ArrayBuffer;
  private checkedCategories: string = 'invitation'
  categoriesObservable: Observable<Category[]>;
  newCategory: string;
  isSubmitButtonsDisabled: boolean = false;
  allPlaces:any = []
  numberTxt:string
  currentPlace:any = {title:"none", tarifs:[]}
  typeCode = "once"
  isQrCode:boolean = false
  countCode:number = 1
  private languageChange: Subject<void> = new Subject<void>();
  qrCodePreview:any
  listCodes:any = []
  countCodes:number = 1
  freeProducts:any = []

  constructor(
    private i18n: I18nService,
    private settings: SettingsService,
    private categories: CategoriesPromotionsService,
    private alert: AlertService,
    private posts: PromotionsService,
    private navigation: NavigationService,
    private places: PostsService,
  ) {

   }

async ngOnInit() {
    this.status = PostStatus.Draft;
    this.languages = this.settings.getActiveSupportedLanguages();
    this.language = this.languages[0].key;
    this.date = new Date().toISOString().slice(0, 10);
    this.image = null;
    this.imageSrc = getEmptyImage();
    this.setCategoriesObservable();   

    var minm = 100000000000;
    var maxm = 999999999999;
    if(this.checkedCategories === 'invitation') {
      this.number = this.generateOrderNumber()
    } else {
      this.number = Math.floor(Math.random() * (100000000000 - 999999999999 + 1)) + 999999999999;
    }
    
    this.numberTxt = this.transform(String(this.number))
    this.allPlaces = this.places.getAll()
    //this.listCodes.push(this.number)
    }


    reCreateNumber() {
      var minm = 100000000000;
      var maxm = 999999999999;
      if(this.checkedCategories === 'invitation') {
        this.number = this.generateOrderNumber()
      } else {
        this.number = Math.floor(Math.random() * (100000000000 - 999999999999 + 1)) + 999999999999;
      }
      this.numberTxt = this.transform(String(this.number))
    }



  ngAfterViewInit() {
    //this.editor = initTextEditor('#editor-container', this.i18n.get('PostContent'));    
  }


  ngOnDestroy() {
    this.languageChange.next();
  }

  private setCategoriesObservable() {
    this.categoriesObservable = this.categories.getWhere('lang', '==', this.language).pipe(
      map((categories: Category[]) => {
        return categories.sort((a: Category, b: Category) => b.createdAt - a.createdAt);
      }),
      takeUntil(this.languageChange)
    );
  }


  getRandomIntInclusive(min) {
    /*
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min +1)) + min;*/
 
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < min; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
    }
    return result;
 
 }
  

  
  onTitleInput() {
    this.slug = slugify(this.title).substr(0, 50);
    this.currentTarif()
  }

  onLanguageChange() {
    this.languageChange.next();
    this.checkedCategories = 'none';
    this.setCategoriesObservable();
  }

  currentTarif() {
    console.log('LE TARIF', this.title)
    this.freeProducts = []
   const tarifFinal = this.currentPlace['tarifs'].find(x => x.name === this.title);
   console.log('Tarif', tarifFinal)

   if(tarifFinal.type === "pack") {
     tarifFinal.packList.forEach(element => {
      this.freeProducts.push(element)
     });
   } else {
    this.freeProducts.push({count: 1, name:this.title})
   }

    return this.title
  }

  addCategory(event: Event) {
    const target = event.target as any;
    target.disabled = true;
    this.categories.add({
      label: this.newCategory,
      slug: slugify(this.newCategory),
      lang: this.language
    }).catch((error: Error) => {
      this.alert.error(error.message);
    }).finally(() => {
      this.newCategory = '';
    });
  }

  /*onCategoryCheck(category: Category, event: Event|any) {
    if (event.target.checked) {
      this.checkedCategories.push(category.slug);
    } else {
      const index = this.checkedCategories.indexOf(category.slug);
      if (index !== -1) {
        this.checkedCategories.splice(index, 1);
      }
    }
  }*/

  onImageChange(event: Event) {
    this.image = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageSrc = reader.result;
    };
    reader.readAsDataURL(this.image);
  }



  addPost(event: Event, status?: PostStatus) {
    const target = event.target as any;
    const startLoading = () => {
      target.isLoading = true;
      this.isSubmitButtonsDisabled = true;
    };
    const stopLoading = () => {
      target.isLoading = false;
      this.isSubmitButtonsDisabled = false;
    };

    this.listCodes = []

    if(this.typeCode === "multi") {
    for (let i = 1; i < this.countCode + 1; i++) {
      if(this.checkedCategories === 'invitation') {
      this.listCodes.push(this.generateOrderNumber())
      } else {
        this.listCodes.push(Math.floor(Math.random() * (100000000000 - 999999999999 + 1)) + 999999999999)
      }
    }
    } else {
      this.listCodes.push(this.number)
    }

    startLoading();
    // Check if post slug is duplicated
    this.posts.isSlugDuplicated(this.slug, this.language).then((duplicated: boolean) => {
      //if (duplicated) {
        // Warn user about post slug
        //this.alert.warning(this.i18n.get('PostSlugAlreadyExists'), false, 5000);
        //stopLoading();
      //} else {
        // Add post
        if (status) {
          this.status = status;
        }
        this.posts.add({
          lang: this.language,
          title: this.title,
          slug: this.slug,
          number: this.number,
          countCodes:this.countCode,
          isQrCode: this.isQrCode,
          listCodes:this.listCodes,
          freeProducts: this.freeProducts,
          typeCode:this.typeCode,
          place: this.place,
          placeName: this.placeName,
          state: this.state,
          date: Math.floor(Date.now()),
          content: 'Code promo / Invitation N° ' + this.number + '' ,
          image: this.image,
          status: this.status,
          categories: this.checkedCategories
        }, this.listCodes, '').then(() => {
          this.alert.success(this.i18n.get('NotificationAdded'), false, 5000, true);
          this.navigation.redirectTo('promotions', 'list');
          if(this.typeCode === "multi") {
            this.createMultiCodePDF()
          } else {
            this.createPDF()
          }
        }).catch((error: Error) => {
          this.alert.error(error.message);
        }).finally(() => {
          stopLoading();
        });
      //}
    }).catch((error: Error) => {
      this.alert.error(error.message);
      stopLoading();
    });
  }

  publishPost(event: Event) {
    this.addPost(event, PostStatus.Published);
  }


  getPlaceTarifs(place:any) {
    this.allPlaces.subscribe(element => {
      element.forEach(element2 => {
        if(element2.docID === place) {
          this.currentPlace = element2
          this.placeName = element2.title
          this.slug = ""
        }
      });
  

    });
  }


  simulatePDF() {

    for (let i = 1; i < this.countCode + 1; i++) {
      if(this.checkedCategories === 'invitation') {
      this.listCodes.push(this.generateOrderNumber())
      } else {
        this.listCodes.push(Math.floor(Math.random() * (100000000000 - 999999999999 + 1)) + 999999999999)
      }
    }

    if(this.typeCode === "multi") {
      this.createMultiCodePDF()
    } else {
      this.createPDF()
    }

    this.listCodes = []
  }

  createMultiCodePDF() {
    var minm = 100000000000;
    var maxm = 999999999999;
    let initItems = 1
    //this.listCodes = []
    const doc = new jsPDF('p', 'mm');
    doc.setFontSize(19);
    if(this.checkedCategories === 'invitation') {
      doc.text("Tickets "+this.placeName+"", 10, 15, null, null);
    } else {
      doc.text("Codes Promo "+this.placeName+"", 10, 15, null, null);
    }
    doc.setFontSize(11);
    doc.setTextColor("gray");
    if(this.checkedCategories === 'invitation') {
      doc.text('(' + this.countCode + ' tickets) ' + this.editor + '\n' + this.editor2 + ' ' + this.editor3 + ' '  + this.editor4 || '', 10, 23, null, null);
    } else {
      doc.text("Codes à utiliser sur l\'application GlissPASS", 10, 23, null, null);
    }
    doc.addImage("assets/images/logo.png", "JPEG", 180, 5, 25, 25);
    doc.setFontSize(14);
    doc.setTextColor("blue");
    if(!this.isQrCode) {
    doc.text(""+this.title+"", 10, 40, null, null);
    }
    let iCount: number = 0
    let iCount2: number = 0
    doc.setFontSize(10);
    doc.setTextColor("black");
    this.listCodes.forEach(element => {
      
      initItems = initItems + 1
      this.number = element //Math.floor(Math.random() * (maxm - minm + 1)) + minm;
      //this.listCodes.push(this.number)
      this.numberTxt = this.transform(String(this.number))
      const ItemPlaceName = this.placeName
      const ItemEditor = this.editor || 'Invitation'
      const ItemEditor2 = this.editor2
      const ItemEditor3 = this.editor3
      const ItemEditor4 = this.editor4
      const ItemTitle = '' //this.title

      if(this.isQrCode) {
        QRCode.toCanvas(String(this.number), { errorCorrectionLevel: 'H', margin:0 }, function (err, canvas) {
          if (err) throw err
          doc.setFontSize(10);
          doc.text(String(ItemPlaceName), 10 + iCount, 45 + iCount2, null, null);
          doc.setFontSize(8);
          doc.setTextColor("gray");
          if(ItemEditor.length > 0) {
            doc.text(String(ItemEditor), 10 + iCount, 45 + iCount2 + 3, null, null);
          }
          if(ItemEditor2.length > 0) {
            doc.text(String(ItemEditor2), 10 + iCount, 45 + iCount2 + 6, null, null);
          }
          if(ItemEditor3.length > 0) {
            doc.text(String(ItemEditor3), 10 + iCount, 45 + iCount2 + 9, null, null);
          }
          if(ItemEditor4.length > 0) {
            doc.text(String(ItemEditor4), 10 + iCount, 45 + iCount2 + 12, null, null);
          }
          doc.setFontSize(8);
          doc.setTextColor("black");
          if(ItemEditor2.length > 0) {
          doc.text(String(ItemTitle), 10 + iCount, 45 + iCount2 + 12, null, null);
          } else {
            doc.text(String(ItemTitle), 10 + iCount, 45 + iCount2 + 8, null, null);
          }
          if(ItemEditor3.length > 0) {
            doc.addImage(canvas, "JPEG", 10 + iCount, 45 + iCount2 + 14, 40, 40);
          } else {
            doc.addImage(canvas, "JPEG", 10 + iCount, 45 + iCount2 + 10, 40, 40);
          }
        })
      } else {
        doc.text("N° "+this.numberTxt+"", 10, 45 + iCount, null, null);
      }

      if(this.isQrCode) {
        iCount = iCount + 50
      } else {
        iCount = iCount + 10
      }

      if(this.isQrCode && iCount === 200) {
        iCount = 0
        iCount2 = iCount2 + 63
      }

      if(initItems && this.isQrCode && initItems === 17) {
        doc.addPage('a4')
        doc.setFontSize(19);
        if(this.checkedCategories === 'invitation') {
          doc.text("Tickets "+this.placeName+"", 10, 15, null, null);
        } else {
          doc.text("Codes Promo "+this.placeName+"", 10, 15, null, null);
        }
        doc.setFontSize(11);
        doc.setTextColor("gray");
        if(this.checkedCategories === 'invitation') {
          doc.text(this.editor, 10, 23, null, null);
        } else {
          doc.text("Codes à utiliser sur l\'application GlissPASS", 10, 23, null, null);
        }
        doc.addImage("assets/images/logo.png", "JPEG", 180, 5, 25, 25);
        if(!this.isQrCode) {
          doc.setFontSize(14);
          doc.setTextColor("blue");
          doc.text(""+this.title+"", 10, 40, null, null);
        }
        doc.setFontSize(10);
        doc.setTextColor("black");
        initItems = 1
        iCount = 0
        iCount2 = 0
      }

      if(initItems === 20 && !this.isQrCode) {
        doc.addPage('a4')
        doc.setFontSize(19);
        doc.text("Tickets "+this.placeName+"", 10, 15, null, null);
        doc.setFontSize(11);
        doc.setTextColor("gray");
        if(this.checkedCategories === 'invitation') {
          doc.text(this.editor, 10, 23, null, null);
        } else {
          doc.text("Codes à utiliser sur l\'application GlissPASS", 10, 23, null, null);
        }
        doc.addImage("assets/images/logo.png", "JPEG", 180, 5, 25, 25);
        if(!this.isQrCode) {
          doc.setFontSize(14);
          doc.setTextColor("blue");
          doc.text(""+this.title+"", 10, 40, null, null);
        }
        doc.setFontSize(10);
        doc.setTextColor("black");
        initItems = 1
        iCount = 0
      }
    })
   
    //Save the .pdf
    doc.save(this.countCode + " tickets - " + this.editor + ".pdf");
  }

  createPDF() {
    const number = this.transform(String(this.number))
    const doc = new jsPDF();
    this.listCodes = []
    const ItemPlaceName = this.placeName
    const ItemEditor = this.editor || 'Invitation'
    const ItemEditor2 = this.editor2
    const ItemTitle = this.title
    let iCount: number = 0
    let iCount2: number = 0

    doc.setFontSize(19);
    if(this.checkedCategories === 'invitation') {
      doc.text("Invitation "+this.placeName+"", 10, 15, null, null);
    } else {
      doc.text("Code Promo "+this.placeName+"", 10, 15, null, null);
    }
    doc.setFontSize(11);
    doc.setTextColor("gray");
    if(this.checkedCategories === 'invitation') {
      doc.text('(' + this.countCode + ' invitations) ' + this.editor || '' + ' ' + this.editor2 || '', 10, 23, null, null);
    } else {
      doc.text("Codes à utiliser sur l\'application GlissPASS", 10, 23, null, null);
    }
    doc.addImage("assets/images/logo.png", "JPEG", 180, 5, 25, 25);
    //All Codes
    if(!this.isQrCode) {
      doc.setFontSize(14);
      doc.setTextColor("blue");
      doc.text(""+this.title+"", 10, 60, null, null);
    }
    doc.setFontSize(12);
    doc.setTextColor("black");

    if(this.isQrCode) {
      QRCode.toCanvas(String(this.number), { errorCorrectionLevel: 'H', margin:0 }, function (err, canvas) {
        if (err) throw err
        doc.setFontSize(10);
        doc.text(String(ItemPlaceName), 10 + iCount, 50 + iCount2, null, null);
        doc.setFontSize(10);
        doc.setTextColor("gray");
        if(ItemEditor.length > 0) {
          doc.text(String(ItemEditor), 10 + iCount, 50 + iCount2 + 4, null, null);
        }
        if(ItemEditor2.length > 0) {
          doc.text(String(ItemEditor2), 10 + iCount, 50 + iCount2 + 8, null, null);
        }
        doc.setFontSize(10);
        doc.setTextColor("black");
        if(ItemEditor2.length > 0) {
        doc.text(String(ItemTitle), 10 + iCount, 50 + iCount2 + 12, null, null);
        } else {
          doc.text(String(ItemTitle), 10 + iCount, 50 + iCount2 + 8, null, null);
        }
        if(ItemEditor2.length > 0) {
          doc.addImage(canvas, "JPEG", 11 + iCount, 50 + iCount2 + 14, 40, 40);
        } else {
          doc.addImage(canvas, "JPEG", 11 + iCount, 50 + iCount2 + 10, 40, 40);
        }
      })
    }

    else {
      doc.text("Code N° "+number+"", 10, 70, null, null);
    }
    //Save the .pdf
    doc.save(""+number+".pdf");
  }


  createQrCodePreview(event) {

    if(event === "false") {
      this.isQrCode = false
    } else {
    QRCode.toDataURL('I am a pony!')
    .then(url => {
      console.log(url)
      this.qrCodePreview = url
    })
    .catch(err => {
      console.error(err)
    })
  }
  }

  transform(value) {
    if (value != null) {
      value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    }
    return value;
  }

  generateOrderNumber() {
    var minm = 1000;
    var maxm = 9999;
    const orderNumber1 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber2 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber3 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber4 = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const orderNumber = (orderNumber1 + '-' + orderNumber2 + '-' + orderNumber3 + '-' + orderNumber4)
    return orderNumber
   }

}

