import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FireAdminComponent } from './fire-admin.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { PostsListComponent } from './components/posts/list/posts-list.component';
import { DevicesListComponent } from './components/devices/list/devices-list.component';
import { CustomersListComponent } from './components/customers/list/customers-list.component';
import { NotificationsListComponent } from './components/notifications/list/notifications-list.component';
import { SessionsListComponent } from './components/sessions/list/sessions-list.component';
import { CartesListComponent } from './components/cartes/list/cartes-list.component';
import { PostsAddComponent } from './components/posts/add/posts-add.component';
import { NotificationsAddComponent } from './components/notifications/add/notifications-add.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { PostsEditComponent } from './components/posts/edit/posts-edit.component';
import { PostsStatsComponent } from './components/posts/stats/posts-stats.component';
import { NotificationsEditComponent } from './components/notifications/edit/posts-edit.component';
import { SessionEditComponent } from './components/sessions/edit/posts-edit.component';
import { CartesEditComponent } from './components/cartes/edit/cartes-edit.component';
import { PostsCategoriesComponent } from './components/posts/categories/posts-categories.component';
import { PostsCategoriesTarifsComponent } from './components/posts/categories-tarifs/posts-categories-tarifs.component';
import { NotificationsCategoriesComponent } from './components/notifications/categories/notifications-categories.component';
import { PagesListComponent } from './components/pages/list/pages-list.component';
import { PagesAddComponent } from './components/pages/add/pages-add.component';
import { PagesEditComponent } from './components/pages/edit/pages-edit.component';
import { PagesTranslateComponent } from './components/pages/translate/pages-translate.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PostsTranslateComponent } from './components/posts/translate/posts-translate.component';
import { UsersListComponent } from './components/users/list/users-list.component';
import { UsersAddComponent } from './components/users/add/users-add.component';
import { UsersProfileComponent } from './components/users/profile/users-profile.component';
import { UsersEditComponent } from './components/users/edit/users-edit.component';
import { TranslationsComponent } from './components/translations/translations.component';
import { UserGuard } from './guards/user.guard';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { RegisterComponent } from './components/register/register.component';
import { RegisterGuard } from './guards/register.guard';
import { LogoutComponent } from './components/logout/logout.component';

import { PartnersListComponent } from './components/partners/list/partners-list.component';
import { PartnersEditComponent } from './components/partners/edit/partners-edit.component';
import { PartnersAddComponent } from './components/partners/add/partners-add.component';
import { PromotionsAddComponent } from './components/promotions/add/promotions-add.component';
import { PromotionsCategoriesComponent } from './components/promotions/categories/partners-categories.component';
import { PartnersCategoriesComponent } from './components/partners/categories/partners-categories.component';
import { PromotionsListComponent } from './components/promotions/list/promotions-list.component';
import { PromotionsEditComponent } from './components/promotions/edit/promotions-edit.component';


const routes: Routes = [
  {
    path: '',
    component: FireAdminComponent,
    children: [
      // Login
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard]
      },
      // Logout
      {
        path: 'logout',
        component: LogoutComponent
      },
      // Register
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [RegisterGuard]
      },
      // Dashboard
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, MaintenanceGuard]
      },
      // Settings
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard, MaintenanceGuard]
      },
      // Pages
      {
        path: 'pages',
        canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: PagesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/author/:authorId',
            component: PagesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: PagesAddComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: PagesEditComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'translate/:id',
            component: PagesTranslateComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
      // Posts
      {
        path: 'posts',
        //canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: PostsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/status/:status',
            component: PostsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/category/:categoryId',
            component: PostsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/author/:authorId',
            component: PostsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: PostsAddComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: PostsEditComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'stats/:id',
            component: PostsStatsComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'translate/:id',
            component: PostsTranslateComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'categories',
            component: PostsCategoriesComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'categories-tarifs',
            component: PostsCategoriesTarifsComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
// Notifications
{
  path: 'notifications',
  canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
  children: [
    {
      path: 'list',
      component: NotificationsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/status/:status',
      component: PostsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/category/:categoryId',
      component: PostsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/author/:authorId',
      component: PostsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'add',
      component: NotificationsAddComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'edit/:id',
      component: NotificationsEditComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'translate/:id',
      component: PostsTranslateComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'categories',
      component: NotificationsCategoriesComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: '**',
      redirectTo: 'list'
    }
  ]
},

// Partners
{
  path: 'partners',
  canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
  children: [
    {
      path: 'list',
      component: PartnersListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/status/:status',
      component: PartnersListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/category/:categoryId',
      component: PartnersListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/author/:authorId',
      component: PartnersListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'add',
      component: PartnersAddComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'edit/:id',
      component: PartnersEditComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'translate/:id',
      component: PostsTranslateComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'categories',
      component: PartnersCategoriesComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: '**',
      redirectTo: 'list'
    }
  ]
},



// Promotions
{
  path: 'promotions',
  canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
  children: [
    {
      path: 'list',
      component: PromotionsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/status/:status',
      component: PromotionsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/category/:categoryId',
      component: PromotionsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'list/author/:authorId',
      component: PromotionsListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'add',
      component: PromotionsAddComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'edit/:id',
      component: PromotionsEditComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'translate/:id',
      component: PostsTranslateComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: 'categories',
      component: PromotionsCategoriesComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
    },
    {
      path: '**',
      redirectTo: 'list'
    }
  ]
},

       // Sessions
       {
        path: 'sessions',
        canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/place/:id',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/status/:status',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/category/:categoryId',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/author/:authorId',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: PostsAddComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: SessionEditComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'translate/:id',
            component: PostsTranslateComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'categories',
            component: PostsCategoriesComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },



       // Sessions
       {
        path: 'devices',
        canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: DevicesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/place/:id',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/status/:status',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/category/:categoryId',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/author/:authorId',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: PostsAddComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: SessionEditComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'translate/:id',
            component: PostsTranslateComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'categories',
            component: PostsCategoriesComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },


      // Customers
      {
        path: 'customers',
        canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: CustomersListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/place/:id',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/status/:status',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/category/:categoryId',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/author/:authorId',
            component: SessionsListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: PostsAddComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: SessionEditComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'translate/:id',
            component: PostsTranslateComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'categories',
            component: PostsCategoriesComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
          
        ]
      },


      // Cartes
      {
        path: 'maintenance',
        children: [
          {
            path: '',
            component: MaintenanceComponent,
            canActivate: [AuthGuard],
          },
        ]

      },

       // Cartes
       {
        path: 'cartes',
        canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: CartesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/status/:status',
            component: CartesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/category/:categoryId',
            component: CartesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/author/:authorId',
            component: CartesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: CartesListComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: CartesEditComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'categories',
            component: PostsCategoriesComponent,
            canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },



      // Users
      {
        path: 'users',
        canActivate: [AuthGuard, MaintenanceGuard],
        children: [
          {
            path: 'list',
            component: UsersListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'list/role/:role',
            component: UsersListComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'add',
            component: UsersAddComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id',
            component: UsersEditComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'edit/:id/:account',
            component: UsersEditComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'profile/:id',
            component: UsersProfileComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: 'profile/:id/:account',
            component: UsersProfileComponent,
      canActivate: [AuthGuard, UserGuard, MaintenanceGuard],
          },
          {
            path: '**',
            redirectTo: 'list'
          }
        ]
      },
      // Translations
      {
        path: 'translations',
        component: TranslationsComponent,
        canActivate: [AuthGuard, UserGuard]
      },
      // 404
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FireAdminRoutingModule { }
